<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="onPopupClosed()"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>New Purchase Order</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="processNextStep()">Next</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <!-- For Preparation Task -->
      <f7-list media-list>
        <f7-list-item
          link
          @click.native="selectProject"
        >
          <div
            class="list-item-inner-start"
            slot="inner-start"
          >
            Project<required-asterisk />
          </div>
          <div
            class="list-item-title"
            slot="title"
          >
            {{ project && project.title ? project.title : 'Select project' }}
          </div>
          <input-icon
            slot="media"
            icon="cube_box"
          ></input-icon>
          <div
            slot="text"
            style="color: red"
          >
            {{ projectErrorMessage }}
          </div>
        </f7-list-item>

        <!-- Due date -->
        <f7-list-input
          type="datepicker"
          placeholder="Select due date"
          error-message-force
          validate
          validate-on-blur
          :value="dueDate"
          :error-message="dueDateErrorMessage"
          :calendar-params="{
            closeOnSelect: true,
            backdrop: true,
            openIn: 'customModal',
            header: true,
            footer: false,
            dateFormat: 'mm/dd/yyyy',
            disabled: {
              from: null,
              to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
            },
            closeOnSelect: true,
          }"
          @calendar:change="dueDate = $event"
        >
          <div slot="label">Due Date<required-asterisk /></div>
          <input-icon
            slot="media"
            icon="alarm"
          ></input-icon>
        </f7-list-input>
      </f7-list>

      <attachment-input
        :projectId="workOrderTaskId"
        modelType="work-order-tasks"
        title="Attachments"
        isHiddenBlock
        attachment-type="attachment"
        add-button-title="Add an Attachment"
        :value="attachmentFiles"
        @input="attachmentFiles = $event"
      ></attachment-input>

      <user-input
        :value="assigneeIds"
        modelType="wo-task"
        @input="assigneeIds = $event"
        @onUserAdded="handleUserAdded"
      ></user-input>

      <select-estimate-popup
        ref="selectEstimatePopup"
        v-on="$listeners"
        @onCreatePO="handleCreatePO"
      ></select-estimate-popup>
      <project-list-popup
        ref="selectProject"
        @onSelected="onSelectedProject"
        :queryFilters="queryFilters"
      ></project-list-popup>
    </f7-page>
  </f7-popup>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
import SelectEstimatePopup from '../popups/SelectEstimatePopup.vue';
import ProjectListPopup from '@/components/popups/ProjectListPopup.vue';
import UserInput from '../inputs/UserInput.vue';
import AttachmentInput from '@/components/inputs/AttachmentInput.vue';
import {
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
  COLLECTION_WO_TASK,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapGetters, mapActions } from 'vuex';

import dashboardService from '../../../../services/dashboard.service';
import poMixin from '../../mixins/purchase-order-mixin';
import _ from 'lodash';
import {
  // toDateCalendar
  toDateFirebase,
  // toDisplayDateString
} from '../../../../utility/datetime';

export default {
  props: {
    isFromGridView: Boolean,
  },
  components: {
    InputIcon,
    SelectEstimatePopup,
    UserInput,
    AttachmentInput,
    ProjectListPopup,
  },
  mixins: [poMixin],
  data() {
    return {
      popupOpened: false,
      needDelete: true,

      projectId: '',
      workOrderId: '',
      workOrderType: 'purchase-order',
      dueDate: [],
      propertyId: '',

      workOrderTaskId: '',
      checklist: '',
      assigneeIds: [],
      attachmentFiles: [],
      description: '',
      BUSINESS_CODE_RESIDENTIAL,
      BUSINESS_CODE_COMMERCIAL,
      BUSINESS_CODE_SERVICE,
    };
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {
    project: {
      required,
    },
    dueDate: {
      required,
    },
  },

  computed: {
    ...mapGetters('purchase-order/purchase-order-details-page/project', [
      'constructionAction',
      'project',
      'actionList',
    ]),

    ...mapGetters('purchase-order/purchase-order-details-page/vendor', [
      'vendorList',
      'vendorById',
    ]),
    // ...mapGetters("common/project", ["actionByCode", "actionList"]),
    ...mapGetters({
      actionListResidential: 'common/project/actionList',
      actionByCode: 'common/project/actionByCode',
    }),
    ...mapGetters('purchase-order/app-constant', ['roofTypeBy']),
    ...mapGetters('setting/app/profile', ['user']),

    projectErrorMessage() {
      if (!this.v$.project.$error) return '';
      if (this.v$.project.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    dueDateErrorMessage() {
      if (!this.v$.dueDate.$error) return '';
      if (this.v$.dueDate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    currentWorkOrderActionId() {
      return this.constructionAction.id;
    },

    isGridView() {
      return this.$f7router.currentRoute.path.includes('purchase-order-grid');
    },

    queryFilters() {
      //project residential: just get project at column build contract, schedule build, completion; service or commercial: get all
      const actionCodes = [830, 840, 850];
      const actions = actionCodes
        .map(code => `actions:${this.actionByCode(code).id}`)
        .join(' OR ');
      const residentialFilter = `projectBusinessCode:${BUSINESS_CODE_SERVICE} OR projectBusinessCode:${BUSINESS_CODE_COMMERCIAL} OR ${actions}`;
      const queryFilter = `${residentialFilter}`;

      return queryFilter;
    },
  },

  methods: {
    ...mapActions('purchase-order/purchase-order', [
      'createWorkOrderTask',
      'deleteWorkOrderTask',
      'createNewPurchaseOrder',
      'updatePurchaseOrder',
    ]),

    ...mapActions('purchase-order/purchase-order-details-page/project', [
      'getActionList',
      'getProject',
      'resetProject',
    ]),
    ...mapActions('purchase-order/purchase-order-details-page/price-list', [
      'getPriceListById',
    ]),
    ...mapActions('purchase-order/purchase-order-details-page/product-item', [
      'bindProductItemListBys',
      'unbindProductItemList',
    ]),
    ...mapActions('purchase-order/purchase-order-details-page/vendor', [
      'bindVendorList',
    ]),
    ...mapActions({
      getActionListResidential: 'common/project/getActionListResidential',
    }),
    ...mapActions('common/notification', ['createNotificationByType']),
    selectProject() {
      this.$refs.selectProject.open();
    },

    async onSelectedProject(projectId) {
      await this.getProject(projectId);
      this.projectId = projectId;
    },

    // eslint-disable-next-line no-unused-vars
    handleUserAdded(val) {
      // console.log("handleUserAdded", val);
    },

    done() {
      this.v$.dueDate.$touch();

      this.v$.checklist.$touch();

      if (this.v$.dueDate.$invalid || this.v$.checklist.$invalid) {
        return;
      }

      // Save new Work order task
      this.saveWOTaskInfo();
    },

    saveWOTaskInfo() {
      this.$f7.preloader.show();
      dashboardService
        .updateWOTaskDoc(this.user.tenantId, this.workOrderTaskId, {
          checklist: this.checklist,
          assigneeIds: this.assigneeIds,
          attachmentFiles: this.attachmentFiles,
          description: this.description,
          dueDate: toDateFirebase(this.dueDate),

          actions: [this.currentWorkOrderActionId],
          taskType: this.workOrderType,
        })
        .then(() => {
          this.needDelete = false;
          this.closePopup();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    selectProperty() {
      this.$refs.selectProperty.open();
    },

    initPopupData() {
      if (_.isEmpty(this.actionList)) {
        this.getActionList();
      }
      if (_.isEmpty(this.actionListResidential)) {
        this.getActionListResidential();
      }
      if (_.isEmpty(this.vendorList)) {
        this.bindVendorList();
      }
    },

    /**
     * Open this popup
     *
     * DEV-183 [Preparation] Input checklist, Assignee when create new Preparation Task (create new with 1 step))
     */
    openPopup() {
      this.needDelete = true;
      this.$f7.preloader.show();
      this.popupOpened = true;
      this.resetProject();
      this.initPopupData();
      // create new temp wo task
      this.createTempWOTask().then(id => {
        this.workOrderTaskId = id;
        this.$f7.preloader.hide();
      });
    },

    /**
     * Close popup
     *
     * DEV-183 [Preparation] Input checklist, Assignee when create new Preparation Task (create new with 1 step))
     */
    closePopup() {
      this.clearData();
      this.popupOpened = false;

      if (this.needDelete && !_.isEmpty(this.workOrderTaskId)) {
        // delete temp wo task

        this.deleteWorkOrderTask(this.workOrderTaskId);
      }
    },

    createTempWOTask() {
      const newTask = {
        status: 'draft',
      };

      return this.createWorkOrderTask(newTask);
    },

    /**
     * Create the task and go to next step
     */
    processNextStep() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      this.gotoSelectEstimate();
      return;
    },

    gotoSelectEstimate() {
      this.$refs.selectEstimatePopup.openPopup({
        projectId: this.project.id,
        actionId: this.currentWorkOrderActionId,
      });
    },

    handlePOButtonClick(poNumber) {
      this.closePopup();
      if (this.isFromGridView) {
        this.$emit('onCreated', { taskNumber: poNumber });
      } else {
        this.$f7router.navigate(`/purchase-order/${poNumber}`, {
          pushState: true,
          reloadAll: true,
        });
      }
    },

    async handleCreatePO({ isBuildYourOwn, data, productByStatus }) {
      const self = this;
      if (data.length === 0) {
        return;
      }

      this.$f7.preloader.show();

      const newPurchaseOrderList = [];
      const poNumbers = [];
      const waitingProduct = [];
      const rejectProduct = [];
      let lastPL = null;
      for (const po of data) {
        const PL = !isBuildYourOwn
          ? await this.getPriceListById(po.items[0].priceListId)
          : null;
        lastPL = PL;
        if (po.vendorId !== '') {
          const woTask = await this.createNewWorkOrderTask(po);
          newPurchaseOrderList.push(woTask);
          this.createNotificationByType({
            data: {
              assignees: woTask.assigneeIds,
              project: {
                title: woTask.taskNumber,
                id: woTask.projectId,
                type: 'task',
                entityName: COLLECTION_WO_TASK,
              },
            },
            type: 'assign-user',
          });
          const productItems = await this.bindProductItemListBys([
            {
              prop: 'vendorId',
              val: po.vendorId,
              op: '==',
            },
          ]);

          po.items.map(item => {
            const product = productItems.find(
              product => product.id === item.id
            );
            if (product && product.isDeleted) {
              rejectProduct.push(item);
            }
            if (
              product &&
              !product.isDeleted &&
              product.status === 'pi-waiting-for-approval'
            ) {
              waitingProduct.push(item);
            }
          });
          poNumbers.push(woTask.docNumber);
        } else {
          if (productByStatus.length > 0) {
            for (const item of po.items) {
              const matchedProduct = productByStatus.find(
                product => product.id === item.id
              );
              if (matchedProduct) {
                if (!matchedProduct.isDeleted) {
                  waitingProduct.push(matchedProduct);
                } else {
                  rejectProduct.push(matchedProduct);
                }
              }
            }
          }
        }
      }
      this.$f7.preloader.hide();
      if (waitingProduct.length > 0) {
        this.$ri.dialog.openWarningDialog({
          title: 'Missing Product',
          content: `Some products require approval (on Price List <strong>${
            lastPL.priceListNumber
          }_${lastPL.displayName})</strong>.</br>${waitingProduct
            .map((product, index) => `${index + 1}. ${product.productItem}`)
            .join('<br>')}<br>We've notified the admin for review by email.`,
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
              this.sendMailHandleProduct(
                waitingProduct,
                rejectProduct,
                'approve-product',
                lastPL
              );
            } else if (index === 1) {
              _sefl.app.dialog.close();
              this.sendMailHandleProduct(
                waitingProduct,
                rejectProduct,
                'approve-product',
                lastPL
              );
            }
          },
        });
      }
      if (rejectProduct.length > 0) {
        if (!_.isEmpty(newPurchaseOrderList)) {
          for (const po of newPurchaseOrderList) {
            const productDelete = rejectProduct.filter(
              e => e.vendorId === po.vendorId
            );
            if (!_.isEmpty(productDelete)) {
              productDelete.forEach(item => {
                for (const key in item) {
                  if (item[key] === undefined) {
                    item[key] = '';
                  }
                }
              });
              this.updatePOProp(po.id, 'itemsDeleted', productDelete);
            }
          }
        }

        this.$ri.dialog.openWarningDialog({
          title: 'Missing Product',
          content: `Some products don’t exist.</br>${rejectProduct
            .map(
              (product, index) =>
                `${index + 1}. ${product.productItem}${
                  product.vendorName ? `. Vendor: ${product.vendorName}` : ''
                }`
            )
            .join('<br>')}`,
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
              this.sendMailHandleProduct(
                waitingProduct,
                rejectProduct,
                'removed-product',
                lastPL
              );
            } else if (index === 1) {
              _sefl.app.dialog.close();
              this.sendMailHandleProduct(
                waitingProduct,
                rejectProduct,
                'removed-product',
                lastPL
              );
            }
          },
        });
      }
      await this.$refs.selectEstimatePopup.closePopup();

      const dialogButtons = [];
      for (const poNumber of poNumbers) {
        dialogButtons.push({
          text: poNumber,
          onClick: () => {
            self.handlePOButtonClick(poNumber);
          },
        });
      }
      if (isBuildYourOwn) {
        this.closePopup();
        if (this.isFromGridView) {
          this.$emit('onCreated', { taskNumber: poNumbers[0] });
        } else {
          this.$f7router.navigate(`/purchase-order/${poNumbers[0]}`, {
            pushState: true,
            reloadAll: true,
          });
        }
      } else {
        this.$f7.dialog
          .create({
            title: 'Generate Purchase Order',
            text: `We have created ${
              poNumbers.length
            } drafts PO(s): ${poNumbers.join(
              ', '
            )}. Please double check quantity before sending it to Vendors`,
            buttons: dialogButtons,
            cssClass: 'po-dialog',
            verticalButtons: !this.$f7.device.desktop,
          })
          .open();
        this.popupOpened = false;
      }
    },

    updatePOProp(id, prop, value) {
      this.updatePurchaseOrder({
        id: id,
        doc: { [prop]: value },
      });
    },
    /**
     * TODO: Need to merge with Generate PO
     */
    createNewWorkOrderTask(po = null) {
      const newTask = {
        itemsDeleted: [],
        attachmentFiles: this.attachmentFiles,
        assigneeIds: this.assigneeIds,
        actions: [this.currentWorkOrderActionId],
        projectId: this.project.id,
        projectNumber: this.project.cardNumber, //for search
        projectName: this.project.title, //for search
        taskType: this.workOrderType,
        dueDate: toDateFirebase(this.dueDate),
        status: 'draft', //for search
      };
      if (po !== null) {
        newTask.items = po.items;
        newTask.vendorId = po.vendorId || '';
        newTask.vendorName =
          (this.vendorById(po.vendorId) || {}).companyName || ''; //for search
        newTask.isBuildYourOwn = po.isBuildYourOwn;
        newTask.estimateId = po.estimateId || '';
        newTask.estimateNumber = po.estimateNumber || ''; //for search
        newTask.roofType = po.roofType || '';
        newTask.roofTypeName = this.roofTypeBy(po.roofType).displayName || ''; //for search
        newTask.priceListId = po.priceListId || '';
        newTask.buildingId = po.buildingId;
        newTask.buildingName = po.buildingName || ''; //for search
        newTask.propertyId = this.project.propertyId || po.propertyId;
        newTask.propertyName =
          this.project.propertyName || po.propertyName || ''; //for search
      }
      return this.createNewPurchaseOrder({
        task: newTask,
        isGridView: this.isGridView,
      });
    },

    /**
     * handle popup closed
     */
    onPopupClosed() {
      this.clearData();
      this.popupOpened = false;
      this.resetProject();
      this.unbindProductItemList();
      this.v$.$reset();
      // this.$emit("navigateToBoard");
    },

    clearData() {
      this.workOrderId = '';
      this.workOrderType = 'purchase-order';
      this.dueDate = [];
      this.propertyId = '';
      this.projectId = '';

      this.workOrderTaskId = '';
      this.checklist = '';
      this.assigneeIds = [];
      this.attachmentFiles = [];
      this.description = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}
</style>
