<template>
  <div>
    <f7-block-title
      class="display-flex justify-content-space-between align-items-center"
      style="flex-wrap: wrap; gap: 8px"
    >
      <div>Products</div>
      <div
        class="display-flex"
        style="flex-wrap: wrap; gap: 8px"
      >
        <f7-button
          outline
          @click="openSetDefaultProductPopup()"
        >
          Set Default Product
        </f7-button>
        <f7-button
          outline
          @click="openAddNewProductPopup()"
        >
          Quick Create Product
        </f7-button>
        <f7-button
          fill
          @click="openAddProductPopup"
        >
          Add Product
        </f7-button>
      </div>
    </f7-block-title>

    <div class="card data-table service-product-table">
      <table>
        <thead>
          <tr>
            <th
              v-for="(item, index) in headers"
              :key="index"
              :class="`${item.cssClass} ${textAlign(item.align)}`"
              :style="item.width"
            >
              {{ item.text }}
              <f7-icon
                v-if="item.tooltip"
                f7="info_circle_fill"
                size="14"
                class="cursor-pointer"
                :class="item.tooltip.id"
              ></f7-icon>
            </th>
            <th
              v-if="isEditing"
              class="text-align-center"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody v-if="existingProducts && existingProducts.length > 0">
          <tr
            v-for="(product, index) in existingProducts"
            :key="index"
          >
            <!-- <td class="label-cell">
              {{ product.sku }}
            </td> -->
            <td
              v-if="$f7.device.desktop || $f7.device.ipad"
              class="label-cell"
            >
              <span v-if="!isEditing">{{ product.productItem }}</span>
              <product-auto-complete-input
                v-else
                :value="product.productItem"
                :data="
                  productListBy({
                    roofType: roofType,
                    categoryId: product.categoryId,
                    subCategoryId: product.subCategoryId,
                  })
                "
                @selected="changeProductItem(product, $event.target.value)"
                @onClickAddProductPhoto="uploadProductPhoto"
              ></product-auto-complete-input>
            </td>
            <td v-else>
              <a @click="$refs.productSheetRef.openSheet(product)">{{
                product.productItem
              }}</a>
            </td>

            <td v-if="$f7.device.desktop || $f7.device.ipad">
              <f7-list>
                <f7-list-input
                  class="input-note"
                  type="text"
                  placeholder="Enter proposal note"
                  :value="product.proposalNote"
                  @change="
                    updateProductProperty({
                      propName: 'proposalNote',
                      value: $event.target.value.trim(),
                      productId: product.id,
                      index,
                    })
                  "
                ></f7-list-input>
              </f7-list>
            </td>
            <td v-else>
              <long-content-block
                :seedId="`note-${product.id}`"
                :content="product.proposalNote"
              ></long-content-block>
            </td>

            <td class="label-cell">
              {{ subCategoryName(product.subCategoryId) }}
            </td>
            <td
              @mouseover="hoverIndex = index"
              @mouseout="hoverIndex = ''"
              class="text-align-left"
            >
              <div
                :class="hoverIndex === index ? 'stepper-raised' : ''"
                class="stepper-qty stepper stepper-small stepper-init margin-top-half"
              >
                <div
                  :class="
                    hoverIndex === index
                      ? 'display-inline-block'
                      : 'display-none'
                  "
                  class="stepper-button-minus"
                  @click="minusPlusQty(index, 'minus')"
                ></div>
                <cell-number-input
                  :stepper="hoverIndex === index ? 'true' : 'false'"
                  :readonly="!isInlineEdit(product.subCategoryId) || !isEditing"
                  numberType="decimal"
                  :fractionDigits="0"
                  placeholder="Actual Quantity"
                  :allowNegativeNumber="false"
                  :value="product.actualQty"
                  @done="
                    updateProductProperty({
                      propName: 'actualQty',
                      value: $event,
                      productId: product.id,
                    }).then(() => {
                      return updateProductProperty({
                        propName: 'orderQty',
                        value: calculateOrderQty($event, product),
                        productId: product.id,
                        index,
                      });
                    })
                  "
                  :suffixes="product.uom"
                ></cell-number-input>
                <div
                  :class="
                    hoverIndex === index
                      ? 'display-inline-block'
                      : 'display-none'
                  "
                  class="stepper-button-plus"
                  @click="minusPlusQty(index, 'plus')"
                ></div>
              </div>
            </td>
            <td
              title="Order Qty"
              class="text-align-right bg-total"
            >
              <p style="width: 85px">
                {{ orderQty(product) + ' ' + (product.packaging || '') }}
              </p>
            </td>
            <!-- <td class="label-cell">{{ product.packaging }}</td>
            <td class="numeric-cell">{{ product.unitPack }}</td>
            <td class="numeric-cell">{{ product.unitSize }}</td>
            <td class="label-cell">{{ product.uom }}</td> -->
            <!-- <td class="numeric-cell">{{ product.wasterFactor }}</td> -->
            <td
              title="Price"
              class="text-align-left"
            >
              <cell-number-input
                :stepper="'true'"
                placeholder="Price"
                numberType="currency"
                :value="product.price"
                :readonly="!isEditing"
                @done="
                  updateProductProperty({
                    propName: 'price',
                    value: $event,
                    productId: product.id,
                  }).then(() => {
                    updateProductProperty({
                      propName: 'priceOverride',
                      value: true,
                      productId: product.id,
                    });
                  })
                "
              ></cell-number-input>
            </td>

            <td class="text-align-right">
              {{ (orderQty(product) * (product.price || 0)) | currencyUSD }}
            </td>

            <td
              v-if="$f7.device.desktop || $f7.device.ipad"
              class="text-align-center"
            >
              <f7-checkbox
                :checked="!!product.priceOverride"
                disabled
              ></f7-checkbox>
            </td>

            <td
              v-if="$f7.device.desktop || $f7.device.ipad"
              class="label-cell"
            >
              <f7-link
                v-for="(color, index) in product.colorPhotos || []"
                :key="index"
                @click="clickPhoto(color)"
                :id="`service-${product.id}-${color.id}`"
              >
                {{
                  `${color.colorName}${
                    index + 1 === (product.colorPhotos || []).length
                      ? ''
                      : ',&nbsp;'
                  }`
                }}
              </f7-link>
            </td>

            <td
              v-if="$f7.device.desktop || $f7.device.ipad"
              class="label-cell"
            >
              {{ product.manufacturer }}
            </td>

            <td v-if="$f7.device.desktop || $f7.device.ipad">
              <ul class="technical-data">
                <li
                  v-for="(attach, index) in product.technicalData"
                  :key="index"
                  :id="`${product.id}-${attach.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`"
                >
                  <a
                    class="display-block"
                    @click="openUrlLink(product, attach)"
                  >
                    {{ attach.name }}
                  </a>
                </li>
              </ul>
            </td>
            <!-- <td class="numeric-cell">{{ product.actualQty }}</td> -->
            <td
              v-if="$f7.device.desktop || $f7.device.ipad"
              title="Waster Factor"
            >
              <cell-number-input
                placeholder="Waster Factor"
                numberType="decimal"
                :value="product.wasterFactor"
                :readonly="!isEditing"
                @done="
                  updateProductProperty({
                    propName: 'wasterFactor',
                    value: $event,
                    productId: product.id,
                  })
                    .then(() => {
                      return updateProductProperty({
                        propName: 'wasterFactorOverride',
                        value: true,
                        productId: product.id,
                      });
                    })
                    .then(() => {
                      updateQuantity({ roofType, sectionId });
                    })
                "
              ></cell-number-input>
            </td>

            <td
              v-if="isEditing"
              class="text-align-center"
              @click="removeProductItem(product)"
            >
              <a href="#">
                <i
                  class="f7-icons"
                  style="color: red"
                  >trash</i
                >
              </a>
            </td>
          </tr>
          <template>
            <tr>
              <td colspan="5"></td>
              <td class="text-align-right text-sub-total">Total:</td>
              <td
                colspan="1"
                class="text-align-right bg-total text-sub-total"
              >
                {{ totalCost | currencyUSD }}
              </td>
              <td
                v-if="$device.desktop"
                colspan="5"
              ></td>
              <td
                v-if="isEditing"
                colspan="1"
              ></td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              class="label-cell no-data"
              :colspan="headers.length + 1"
            >
              <div>No items!</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <f7-popover class="popover-menu" :opened="isOpenedSection">
      <f7-list>
        <f7-list-item
          v-for="section in estimateTemplate"
          :key="section.sectionId"
          link="#"
          @click="openAddProductPopup(section.sectionId)"
          popover-close
          :title="section.category"
        ></f7-list-item>
      </f7-list>
    </f7-popover> -->

    <building-add-product-service-popup
      :roofType="roofType"
      :popupAddNewProductItem="popupAddNewProductItem"
      :existingProductItems="existingProducts"
      @close="popupAddNewProductItem = false"
      @onAddProductItems="addProductItems($event)"
      @removeProduct="handleRemoveProductItem($event)"
      :manufacturers="[]"
      :vendorIds="[]"
      :estimateId="''"
      @copyProduct="openAddNewProductPopup($event)"
      ref="addProductPopup"
      @goToPage="goToPage"
    />

    <product-sheet ref="productSheetRef"></product-sheet>

    <image-uploader
      ref="fileInput"
      :preview="false"
      :quality="0.7"
      class="display-none"
      accept="image/*"
      doNotResize="gif"
      :autoRotate="false"
      outputFormat="verbose"
      @input="handleFileUpload"
    ></image-uploader>

    <f7-photo-browser
      :photos="productPhotos"
      theme="dark"
      :ref="`detail_pageDark`"
      type="popup"
    ></f7-photo-browser>

    <add-new-product-popup ref="addNewProductPopup"></add-new-product-popup>
    <set-default-product-popup
      ref="setDefaultProductPopup"
      @onSaveProductItems="saveDefaultProductItems($event)"
      @onRemoveProductItems="onRemoveDefaultProductItems($event)"
    ></set-default-product-popup>
  </div>
</template>

<script>
import CellNumberInput from '@/components/inputs/CellNumberInput.vue';
import ProductAutoCompleteInput from '../inputs/ProductAutoCompleteInput.vue';
import BuildingAddProductServicePopup from '../popup/BuildingAddProductServicePopup.vue';
import AddNewProductPopup from '../popup/AddNewProductPopup.vue';
import SetDefaultProductPopup from '../popup/SetDefaultProductPopup.vue';
import ProductSheet from '../sheets/ProductSheet.vue';
import LongContentBlock from '@/components/blocks/LongContentBlock.vue';
import {
  isVideo,
  isImage,
  createMediaArray,
  openAttackLink,
} from '@/utility/common';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

import { ESTIMATE_PRODUCT_TABLE_HEARDER } from '../../utility/const';
import {
  DEFAULT_STATUS_PRODUCT_ITEM,
  STATUS_PRODUCT_ITEM_WFA,
} from '../../../../utility/const';

import buildingMixins from '../../utility/building-mixins';

export default {
  components: {
    CellNumberInput,
    ProductAutoCompleteInput,
    BuildingAddProductServicePopup,
    ProductSheet,
    LongContentBlock,
    AddNewProductPopup,
    SetDefaultProductPopup,
  },

  mixins: [buildingMixins],

  props: {
    existingProducts: { type: Array, default: () => [] },
    roofType: String,
  },

  data() {
    return {
      ESTIMATE_PRODUCT_TABLE_HEARDER,
      isEditing: true,
      // sectionId: '',

      isOpenedSection: false,
      popupAddNewProductItem: false,
      photoProductId: '',
      hoverIndex: '',
      stepper: false,
      productPhotos: [],
      productList: [],
    };
  },
  computed: {
    ...mapGetters({
      subCategories: 'estimate/estimate-page/estimate/sub-category/objectList',
      vendors: 'estimate/estimate-page/estimate/vendor/objectList',
    }),

    ...mapGetters('estimate/estimate-page/price-list', {
      priceListObject: 'priceList',
    }),

    ...mapGetters('estimate/estimate-page/price-list', ['priceListItemList']),

    ...mapGetters('estimate/estimate-page/estimate', [
      'currentBuilding',
      'estimate',
    ]),

    ...mapGetters({
      categories: 'estimate/estimate-page/estimate/category/objectList',
    }),

    ...mapGetters('estimate/estimate-page/product-item', ['hits']),
    ...mapGetters({
      productListData: 'estimate/estimate-page/product-item/productList',
    }),
    vendorName() {
      return id => {
        const vendor = this.vendors.find(r => r.id === id);
        return (vendor || {}).companyName;
      };
    },

    vendorIdList() {
      return _.isEmpty(this.currentBuilding)
        ? []
        : this.currentBuilding.vendorIds;
    },

    subCategoryName() {
      return subCategoryId => {
        return (
          (this.subCategories.find(item => item.id === subCategoryId) || {})
            .name || ''
        );
      };
    },

    categoryBy() {
      return categoryId => {
        return this.categories.find(item => item.id === categoryId) || {};
      };
    },

    sectionBy() {
      return categoryName => {
        return (
          this.estimateTemplate.find(item => item.category === categoryName) ||
          {}
        );
      };
    },

    productItemList() {
      const products = this.productList.concat(this.hits);

      return products.map(orgProduct => {
        const sectionId = this.sectionBy(
          this.categoryBy(orgProduct.categoryId || '').name
        ).sectionId;

        if (!sectionId) {
          return {};
        }
        const refProduct = (
          (this.priceListObject || {}).productRefs || []
        ).find(item => item.productId === orgProduct.id);

        return {
          ...orgProduct,
          sectionId: sectionId,
          vendorPrice: !Number.isNaN(Number.parseFloat(refProduct?.vendorPrice))
            ? refProduct.vendorPrice
            : !Number.isNaN(Number.parseFloat(refProduct?.price))
              ? refProduct.price
              : orgProduct.price,
          markup: refProduct?.markup
            ? refProduct.markup
            : { value: 0, type: 'percent' },
          price: !Number.isNaN(Number.parseFloat(refProduct?.price))
            ? refProduct.price
            : orgProduct.price,
          internalNote: refProduct?.internalNote || '',
          proposalNote: refProduct?.proposalNote || '',
        };
      });
    },

    // priceListProductItemList() {
    //   return this.productItemList.filter(product => {
    //     return !this.items.find(item => item.id === product.id);
    //   });
    // },

    productListBy() {
      return ({ roofType, categoryId, subCategoryId }) => {
        if (!this.productListData) return [];
        if (this.roofType === 'all-roof-type') {
          return this.productListData.filter(
            item =>
              item.categoryId === categoryId &&
              item.subCategoryId === subCategoryId &&
              item.status === DEFAULT_STATUS_PRODUCT_ITEM
          );
        }
        return this.productListData.filter(
          item =>
            (item.roofTypes || []).includes(roofType) &&
            item.categoryId === categoryId &&
            item.subCategoryId === subCategoryId &&
            item.status === DEFAULT_STATUS_PRODUCT_ITEM
        );
      };
    },

    // TODO: double check this busibness
    isInlineEdit() {
      return subCategoryId => {
        return true;
      };
    },

    headers() {
      let h = ESTIMATE_PRODUCT_TABLE_HEARDER;
      if (!(this.$f7.device.desktop || this.$f7.device.ipad)) {
        h = h.filter(
          item =>
            item.value != 'color' &&
            item.value != 'manufacturer' &&
            item.value != 'technicalData' &&
            item.value != 'waster_factor' &&
            item.value != 'priceOverride'
        );
      }
      return h;
    },
    productDataSorted() {
      const list = _.cloneDeep(this.currentBuilding.productData || [])
        .map(section => {
          const productListOrder = (section.productList || []).filter(
            item => !!item.actualQty || !!item.orderQty
          );
          return {
            ...section,
            productList: productListOrder,

            totalCost: (productListOrder || [])
              .map(
                product => (this.orderQty(product) || 0) * (product.price || 0)
              )
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              ),
          };
        })
        .sort((a, b) => {
          return a.index - b.index;
        });
      return list;
    },
    totalCost() {
      return (this.productDataSorted || []).reduce(
        (sum, item) => sum + item.totalCost,
        0
      );
    },
  },

  methods: {
    ...mapActions('estimate/estimate-page/product-item', [
      'updateProduct',
      'createProduct',
      'uploadPhoto',
      'getProduct',
    ]),
    ...mapActions('estimate/estimate-page/price-list', ['addProductRefs']),
    uploadProductPhoto(productId) {
      this.photoProductId = productId;
      this.$refs.fileInput.$el.lastChild.click();
    },
    orderQty(product) {
      const roundUpNumber = numberOfFixedRounds => value => {
        value = Math.round(value * 1000) / 1000;
        const denominator = Math.pow(10, numberOfFixedRounds);
        const temp = parseFloat((value * denominator).toFixed(10)); //Remove zero after device
        return Math.ceil(temp) / denominator;
      };
      const productSize = (product.unitSize || 1) * (product.unitPack || 1);
      const actualQty = product.actualQty * 1;
      const orderQty = actualQty / productSize;
      return roundUpNumber(0)(orderQty);
    },
    handleFileUpload(file) {
      const self = this;
      self.$f7.preloader.show();

      this.uploadPhoto(file)
        .then(({ fullPath, url }) => {
          return {
            thumbnailUrl: url,
            thumbnailFullPath: fullPath,
            url: url,
            name: file.info.name,
            fullPath: fullPath,
          };
        })
        .then(photo => {
          return self.updateProduct({
            id: self.photoProductId,
            doc: { photos: [photo] },
          });
        })
        .finally(() => {
          self.photoProductId = '';
          self.$refs.fileInput.$el.lastChild.value = '';
          self.$f7.preloader.hide();
        });
    },

    updateEstimateCalculatedFields() {},

    updateProductProperty({ propName, value, productId }) {
      const productItem = _.cloneDeep(
        this.existingProducts.find(item => item.id === productId) || {}
      );

      if (_.isEmpty(productItem)) {
        return;
      }

      productItem[propName] = value;
      if (propName === 'price') {
        let vendorPrice = 0;
        const markup = _.cloneDeep(productItem.markup) || {
          value: 0,
          type: 'percent',
        };
        if (markup.type === 'percent') {
          vendorPrice = (100 * value) / (100 + markup.value);
        } else {
          vendorPrice = value - markup.value;
        }
        vendorPrice = parseFloat(vendorPrice.toFixed(2));
        productItem.vendorPrice = vendorPrice;
      }

      return this.updateProductItem({
        roofType: this.roofType,
        sectionId: productItem.sectionId,
        product: productItem,
      });
    },
    updateEstimateField() {},
    // checkQtyInlineEdit() {},

    changeProductItem(oldProduct, newProduct) {
      this.$emit('onChangeProductItem', { oldProduct, newProduct });
    },

    toggleEditing() {
      this.isEditing = !this.isEditing;
    },

    openAddProductPopup() {
      // this.sectionId = sectionId;
      this.popupAddNewProductItem = true;
    },

    async openAddNewProductPopup(data) {
      const addData = await this.$refs.addNewProductPopup.startEditor(
        data && data.item ? 'Copy Product' : 'Create New Product',
        data && data.item
          ? {
              ...data.item,
              status: STATUS_PRODUCT_ITEM_WFA,
              priceListRefs: [this.priceListObject.id],
            }
          : {
              sku: '',
              manufacturer: '',
              productItem: '',
              subCategoryId: '',
              categoryId:
                (this.categories.find(r => r.name === this.sectionTitle) || {})
                  .id || '',
              uom: '',
              roofTypes: [this.currentBuilding.roofType],
              price: '',
              wasterFactor: '',
              size: '',
              vendorId: '',
              color: '',
              status: STATUS_PRODUCT_ITEM_WFA,
              priceListRefs: [this.priceListObject.id],
            }
      );
      if (!addData) return;

      this.$f7.preloader.show();
      const productId = await this.createProduct(addData.productData);
      if (data && data.checked) {
        this.$refs.addProductPopup.addProductItem();
      }
      // update productRefs for product
      this.addProductRefs({
        id: this.priceListObject.id,
        product: {
          productId,
          vendorPrice: addData.productData.price,
          price: addData.price,
          markup: addData.markup,
          internalNote: addData.internalNote,
          proposalNote: addData.proposalNote,
        },
      }).then(async () => {
        const product = await this.getProduct(productId);
        const sectionId =
          this.sectionBy(this.categoryBy(product.categoryId || '').name)
            .sectionId || null;
        const sectionProducts =
          _.cloneDeep(
            this.existingProducts.filter(item => item.sectionId === sectionId)
          ) || [];
        sectionProducts.push({
          ...product,
          vendorPrice: addData.productData.price,
          price: addData.price,
          markup: addData.markup,
          actualQty: 1,
          orderQty: this.calculateOrderQty(1, product),
          isAddManually: true,
          internalNote: addData.internalNote,
          proposalNote: addData.proposalNote,
        });
        this.saveProductData({
          sectionId: sectionId,
          productList: sectionProducts,
          category:
            this.templateSection(this.roofType, sectionId).category || null,
          index: this.templateSection(this.roofType, sectionId).index || null,
        }).then(() => {
          this.$f7.preloader.hide();
          if (data && data.item) {
            this.popupAddNewProductItem = false;
          }
        });
        // send mail
        this.sendMailAddNewProduct(product);
      });
    },

    /**
     *
     * @param {*} productIds the list of product ids
     * We will do 3 steps:
     *  1. if the new id is not exist => get the real product object and add to the section
     *  2. if the new id is exsit => keep the old product object => do nothing
     *  3. if the old id is not exist on the new list of new id => remove the product from the section
     */
    async addProductItems(products = []) {
      this.$f7.preloader.show();
      const productIds = products.map(item => item.id);

      //remove all products
      if (_.isEmpty(productIds)) {
        if (this.existingProducts.length > 0) {
          const productsRemove = _.cloneDeep(this.existingProducts);
          for (const prod of productsRemove) {
            await this.handleRemoveProductItem(prod);
          }
        }
        this.popupAddNewProductItem = false;
        this.$f7.preloader.hide();
        return;
      }

      // Step #1:
      const existingProductIds = (this.existingProducts || []).map(
        item => item.id
      );
      const newProductToAdd = products.filter(
        item => !existingProductIds.includes(item.id)
      );
      // caculate price and save product to section
      for (const orgProduct of newProductToAdd) {
        // const orgProduct = await this.getProduct(id);
        if (!_.isEmpty(orgProduct)) {
          const orderQty = this.calculateOrderQty(1, orgProduct);
          const priceListProduct = this.productItemList.find(
            item => item.id === orgProduct.id
          );

          if (_.isEmpty(priceListProduct)) {
            continue;
          }

          const temp = {
            ...orgProduct,
            sectionId: priceListProduct.sectionId,
            vendorPrice: priceListProduct.vendorPrice,
            markup: priceListProduct.markup,
            price: priceListProduct.price,
            internalNote: priceListProduct.internalNote,
            proposalNote: priceListProduct.proposalNote,
            isAddManually: true,
            actualQty: 1,
            orderQty,
          };

          await this.saveProductItemSection(temp);
        }
      }

      // Step #2: keep existing products => do nothing

      // Step #3: remove the product from the section
      const oldProductIds = existingProductIds.filter(
        id => !productIds.includes(id)
      );
      for (const id of oldProductIds) {
        const orgProduct = await this.getProduct(id);
        if (!_.isEmpty(orgProduct)) {
          await this.handleRemoveProductItem(orgProduct);
        }
      }

      this.popupAddNewProductItem = false;
      this.$f7.preloader.hide();

      return;

      // const refs = [];
      // for (const id of productIds) {
      //   refs.push(this.getProduct(id));
      // }

      // return Promise.all(refs).then(async products => {
      //   for (const orgProduct of products) {
      //     const orderQty = this.calculateOrderQty(1, orgProduct);
      //     const priceListProduct = this.productItemList.find(
      //       item => item.id === orgProduct.id
      //     );

      //     if (_.isEmpty(priceListProduct)) {
      //       continue;
      //     }

      //     const temp = {
      //       ...orgProduct,
      //       sectionId: priceListProduct.sectionId,
      //       price: priceListProduct.price,
      //       internalNote: priceListProduct.internalNote,
      //       proposalNote: priceListProduct.proposalNote,
      //       isAddManually: true,
      //       actualQty: 1,
      //       orderQty
      //     };

      //     await this.saveProductItemSection(temp);
      //   }

      //   this.popupAddNewProductItem = false;
      // });
    },
    goToPage() {
      this.productList = this.productList.concat(this.hits);
    },

    async handleRemoveProductItem(product) {
      const sectionId =
        this.sectionBy(this.categoryBy(product.categoryId || '').name)
          .sectionId || null;
      const sectionProducts =
        _.cloneDeep(
          this.existingProducts.filter(item => item.sectionId === sectionId)
        ) || [];
      if (sectionProducts.some(r => r.id === product.id)) {
        _.remove(sectionProducts, item => item.id === product.id);
        const templateSection = this.templateSection(this.roofType, sectionId);

        await this.saveProductData({
          sectionId: sectionId,
          productList: sectionProducts,
          category: templateSection.category,
          index: templateSection.index,
        });

        await this.deleteEstimateHistoryBys({
          estimateId: this.estimate.id,
          conditions: [
            {
              prop: 'productId',
              val: product.id,
              op: '==',
            },
            {
              prop: 'buildingId',
              val: this.currentBuilding.id,
              op: '==',
            },
            {
              prop: 'sectionId',
              val: sectionId,
              op: '==',
            },
          ],
        });
      }
      // this.popupAddNewProductItem = false;
    },

    removeProductItem(product) {
      this.$ri.dialog.openWarningDialog({
        title: 'Remove Product',
        content: 'Do you want to remove this product?',
        textButton: 'Remove',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.handleRemoveProductItem(product);
          }
        },
      });
    },
    async saveDefaultProductItems(products) {
      for (const product of products) {
        const orderQty = this.calculateOrderQty(1, product);

        const temp = {
          ...product,
          sectionId: product.sectionId,
          vendorPrice: product.vendorPrice,
          markup: product.markup,
          price: product.price,
          internalNote: product.internalNote,
          proposalNote: product.proposalNote,
          isAddManually: true,
          actualQty: 1,
          orderQty,
        };

        await this.saveProductItemSection(temp);
      }
    },

    async onRemoveDefaultProductItems(products) {
      const refs = [];
      for (const product of products) {
        refs.push(this.handleRemoveProductItem(product));
      }
      await Promise.all(refs);
    },
    textAlign(align) {
      return align === 'left'
        ? 'text-align-left'
        : align === 'center'
          ? 'text-align-center'
          : align === 'right'
            ? 'text-align-right'
            : 'text-align-left';
    },
    openUrlLink(item, attach) {
      this.productPhotos = createMediaArray(item.technicalData);
      openAttackLink(attach, this.productPhotos, this.$refs[`detail_pageDark`]);
    },
    clickPhoto(item) {
      this.productPhotos = (item || {}).photos || [];
      if (this.productPhotos.length > 0) {
        setTimeout(() => this.$refs[`detail_pageDark`].open(0), 100);
      }
    },
    createColorPhotoTooltip() {
      this.$nextTick(() => {
        this.headers.forEach(header => {
          if (header.tooltip) {
            this.$f7.tooltip.create({
              targetEl: `.${header.tooltip.id}`,
              text: `${header.tooltip.content}`,
            });
          }
        });
        this.existingProducts.forEach(item => {
          (item.colorPhotos || []).forEach(color => {
            const el = this.$f7.tooltip.get(`#service-${item.id}-${color.id}`);
            if (el) {
              this.$f7.tooltip.destroy(`#service-${item.id}-${color.id}`);
            }

            const photo = (color.photos || [])[0];
            const tooltipContent = photo
              ? `<img class="tooltip-image-show" src="${photo.thumbnailUrl}" />`
              : `<span>No image !</span>`;
            this.$f7.tooltip.create({
              targetEl: `#service-${item.id}-${color.id}`,
              cssClass: photo ? 'tooltip-image-preview' : '',
              text: tooltipContent,
            });
          });
        });
        this.existingProducts.forEach(attachMent => {
          (attachMent.technicalData || []).forEach(item => {
            const el1 = this.$f7.tooltip.get(
              `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`
            );
            if (el1) {
              this.$f7.tooltip.destroy(
                `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`
              );
            }
            const tooltipContent = isImage(item.url)
              ? `<img class="tooltip-image-show" src="${item?.url}" />`
              : isVideo(item.url)
                ? `<video class="tooltip-image-show" src="${item?.url}" ></video>`
                : `<span>${item?.name}</span>`;
            this.$f7.tooltip.create({
              targetEl: `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`,
              cssClass:
                isImage(item.url) || isVideo(item.url)
                  ? 'tooltip-image-preview'
                  : '',
              text: tooltipContent,
            });
          });
        });
      });
    },

    minusPlusQty(index, type) {
      const sectionProducts = this.existingProducts;
      const currentProduct = sectionProducts[index];
      const currentQty = currentProduct.actualQty || 0;
      const newQty =
        type === 'minus' ? Math.max(currentQty - 1, 0) : currentQty + 1;

      // Update actualQty
      this.updateProductProperty({
        propName: 'actualQty',
        value: newQty,
        productId: currentProduct.id,
        index,
      }).then(() => {
        // Update orderQty
        const orderQty = this.calculateOrderQty(newQty, currentProduct);
        return this.updateProductProperty({
          propName: 'orderQty',
          value: orderQty,
          productId: currentProduct.id,
          index,
        });
      });
    },

    openSetDefaultProductPopup() {
      this.$refs.setDefaultProductPopup.open();
    },
  },
  updated() {
    this.createColorPhotoTooltip();
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  text-align: center;
  color: grey;
}
//mobile css
@media (min-width: 320px) and (max-width: 480px) {
  .card.data-table {
    overflow: auto;
  }
}

.service-product-table {
  table:first-child {
    table-layout: fixed;

    //Product Item
    th:nth-child(1) {
      width: 200px;
    }

    //Note
    th:nth-child(2) {
      width: 200px;
    }

    // Qty
    th:nth-child(3) {
      width: 100px;
    }
    // Order Qty
    th:nth-child(4) {
      width: 100px;
    }
    // Price
    th:nth-child(5) {
      width: 100px;
    }
    // Total Cost
    th:nth-child(6) {
      width: 100px;
    }
    // Total Cost
    th:nth-child(7) {
      width: 100px;
    }
    // Price Override
    th:nth-child(8) {
      width: 110px;
    }

    // Color
    th:nth-child(9) {
      width: 80px;
    }
    // Manufacturer
    th:nth-child(10) {
      width: 200px;
    }
    // Technical Data
    th:nth-child(11) {
      width: 150px;
    }
    // Waste Factor
    th:nth-child(12) {
      width: 70px;
    }

    // Action
    th:nth-child(13) {
      width: 70px;
    }

    td {
      word-wrap: break-word;
    }
  }
}

.service-product-table::v-deep tbody tr td:nth-child(4) {
  ul {
    width: 100%;
    padding-left: 20px;
    li {
      a {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 480px) and (orientation: portrait) {
  .service-product-table table:first-child {
    th {
      padding: 0px 10px;
    }
    //Product Item
    th:nth-child(1) {
      width: 140px;
    }
    // Package Qty
    th:nth-child(2) {
      width: 100px;
    }
    // Price
    th:nth-child(3) {
      width: 100px;
    }
    // Total Cost
    th:nth-child(4) {
      width: 120px;
    }

    // Action
    th:nth-child(5) {
      width: 70px;
    }
  }

  .service-product-table ::v-deep tbody tr {
    td {
      padding: 0px 10px;
    }
  }
}
.input-note ::v-deep .item-content {
  padding-left: 0;
}
.technical-data {
  width: 120px;
  display: inline-block;
  list-style-type: disc;
}
.technical-data a {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.technical-data li {
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--f7-theme-color);
}

.technical-data li::marker {
  color: var(--f7-color-text-neutral);
}
</style>
