<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ assemblyTemplate.templateName }}</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>

    <f7-block>
      <!-- <f7-block-title
        >Assembly Template page under construction!</f7-block-title
      > -->
      <!-- :buildingId="`${currentBuilding.id}_${index}_${assembly.assemblyItem}`" -->
      <!-- :items="productsAreAutoGenerate(assembly.productList)" -->
      <f7-row>
        <f7-col
          width="100"
          :xsmall="$device.desktop ? '80' : '100'"
          style="position: relative"
        >
          <component
            :is="assemblyTemplate.canvasComponent"
            :ref="`_${assemblyTemplate.templateCode}`"
            :data="assemblyTemplate.measurement || {}"
            :entityId="assemblyTemplate.id"
            :isMetalDrawing="true"
            :items="[]"
            @setFields="
              setAssemblyMeasurement(
                index,
                $event.name,
                $event.value,
                $event.callback
              )
            "
            :photoSelected="photoSelected"
            @setPhotoSelected="setPhotoSelected"
          ></component>
          <!-- zoom in/out -->
          <f7-segmented
            v-show="assemblyTemplate.canvasComponent"
            raised
            tag="p"
            class="btn-zoom"
            :style="!$device.desktop && 'top: 0'"
          >
            <f7-button
              @click="handleZoom('in', `_${assemblyTemplate.templateCode}`)"
              >+</f7-button
            >
            <f7-button
              @click="handleZoom('out', `_${assemblyTemplate.templateCode}`)"
              >-</f7-button
            >
          </f7-segmented>
        </f7-col>

        <f7-col
          width="100"
          :xsmall="$device.desktop ? '20' : '100'"
        >
          <!-- common form -->
          <!-- <common-measurement-form
            :assembly="assembly || {}"
            :index="index"
            :roofType="ROOF_TYPE_SHINGLE"
            :canvasId="
              `${currentBuilding.id}_${index}_${assembly.assemblyItem}`
            "
            @changeAssemblyProp="changeAssemblyProp"
            @changeTemplate="
              changeTemplate({
                index: $event.target.index,
                value: $event.target.value,
                roofType: ROOF_TYPE_SHINGLE,
                sectionId: SECTION_TYPE_SHINGLE_METAL_AND_EDGE
              })
            "
            @removeAssembly="removeAssembly"
          >
          </common-measurement-form> -->
          <!-- Measurement title -->
          <f7-row class="row-action">
            <f7-col
              ><f7-row class="justify-content-flex-start"
                ><f7-block-title>Measurement</f7-block-title></f7-row
              ></f7-col
            >
            <!-- <f7-col
              ><f7-row class="justify-content-flex-end"
                ><f7-button
                  v-if="!$device.desktop && assembly.assemblyItem"
                  outline
                  style="margin-top: 10px; margin-right: 10px;"
                  @click="openAssemblyPopup(assembly)"
                  >View Diagram</f7-button
                ></f7-row
              ></f7-col
            > -->
          </f7-row>

          <!-- <f7-block-title>Measurement</f7-block-title> -->
          <component
            :is="assemblyTemplate.formComponent"
            :data="assemblyTemplate.measurement || {}"
            :photoSelected="photoSelected"
            :isDisable="true"
            @setPhotoSelected="setPhotoSelected"
          ></component>
          <!-- @setFields="
              setAssemblyMeasurement(
                index,
                $event.name,
                $event.value,
                $event.callback
              ).then(res => {
                if (!res) return;
                if ($event.name.match(/[a-z]1/)) {
                  checkMetalStretchOut(checkingStretchOutAssembly);
                } else if (
                  $event.name.match(/[a-z]2/) ||
                  $event.name.match(/[a-z]3/)
                ) {
                  checkCleatStretchOut(checkingStretchOutAssembly);
                } else {
                  checkMetalStretchOut(checkingStretchOutAssembly);
                }
              })
            " -->

          <!-- <f7-row v-if="$device.desktop">
            <f7-col width="100" xsmall="50">
              <f7-button fill @click="removeAssembly(index)">Remove</f7-button>
            </f7-col>
            <f7-col width="100" xsmall="50"></f7-col>
          </f7-row> -->
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import CopingWithCleatFastener from '@/components/metal-canvas/canvas/CopingWithCleatFastener.vue';
import CopingWithCleatFastenerForm from '@/components/metal-canvas/forms/CopingWithCleatFastenerForm.vue';
import CopingWithCleat from '@/components/metal-canvas/canvas/CopingWithCleat.vue';
import CopingWithCleatForm from '@/components/metal-canvas/forms/CopingWithCleatForm.vue';

import CopingWithFrontBackCleat from '@/components/metal-canvas/canvas/CopingWithFrontBackCleat.vue';
import CopingWithFrontBackCleatForm from '@/components/metal-canvas/forms/CopingWithFrontBackCleatForm.vue';

import Embedded from '@/components/metal-canvas/canvas/Embedded.vue';
import EmbeddedForm from '@/components/metal-canvas/forms/EmbeddedForm.vue';
import CollectorHead from '@/components/metal-canvas/canvas/CollectorHead.vue';
import CollectorHeadForm from '@/components/metal-canvas/forms/CollectorHeadForm.vue';
import DownspoutTransition from '@/components/metal-canvas/canvas/DownspoutTransition.vue';
import DownspoutTransitionForm from '@/components/metal-canvas/forms/DownspoutTransitionForm.vue';
import BoxDownspout from '@/components/metal-canvas/canvas/BoxDownspout.vue';
import BoxDownspoutForm from '@/components/metal-canvas/forms/BoxDownspoutForm.vue';
import GutterSystem from '@/components/metal-canvas/canvas/GutterSystem.vue';
import GutterSystemForm from '@/components/metal-canvas/forms/GutterSystemForm.vue';
import HeadwallFlashing from '@/components/metal-canvas/canvas/HeadwallFlashing.vue';
import HeadwallFlashingForm from '@/components/metal-canvas/forms/HeadwallFlashingForm.vue';
import PitchPan from '@/components/metal-canvas/canvas/PitchPan.vue';
import PitchPanForm from '@/components/metal-canvas/forms/PitchPanForm.vue';
import CounterFlashing from '@/components/metal-canvas/canvas/CounterFlashing.vue';
import CounterFlashingForm from '@/components/metal-canvas/forms/CounterFlashingForm.vue';

// Standing Seam
import Valley from '@/components/metal-canvas/canvas/Valley.vue';
import ValleyForm from '@/components/metal-canvas/forms/ValleyForm.vue';
import SidewallOrHeadwall from '@/components/metal-canvas/canvas/SidewallOrHeadwall.vue';
import SidewallOrHeadwallForm from '@/components/metal-canvas/forms/SidewallOrHeadwallForm.vue';
import Ridge from '@/components/metal-canvas/canvas/Ridge.vue';
import RidgeForm from '@/components/metal-canvas/forms/RidgeForm.vue';
import ZeeTrim from '@/components/metal-canvas/canvas/ZeeTrim.vue';
import ZeeTrimForm from '@/components/metal-canvas/forms/ZeeTrimForm.vue';
import ExtendedEaveOrRake from '@/components/metal-canvas/canvas/ExtendedEaveOrRake.vue';
import ExtendedEaveOrRakeForm from '@/components/metal-canvas/forms/ExtendedEaveOrRakeForm.vue';

import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    CopingWithCleatFastener,
    CopingWithCleatFastenerForm,
    CopingWithCleat,
    CopingWithCleatForm,

    CopingWithFrontBackCleat,
    CopingWithFrontBackCleatForm,
    Embedded,
    // CommonMeasurementForm,
    EmbeddedForm,
    CollectorHead,
    CollectorHeadForm,
    DownspoutTransition,
    DownspoutTransitionForm,
    BoxDownspout,
    BoxDownspoutForm,
    GutterSystem,
    GutterSystemForm,
    HeadwallFlashing,
    HeadwallFlashingForm,
    PitchPan,
    PitchPanForm,
    CounterFlashing,
    CounterFlashingForm,
    Valley, //Standing Seam
    ValleyForm, //Standing Seam
    SidewallOrHeadwall, //Standing Seam
    SidewallOrHeadwallForm, //Standing Seam
    Ridge, //Standing Seam
    RidgeForm, //Standing Seam
    ZeeTrim, //Standing Seam
    ZeeTrimForm, //Standing Seam
    ExtendedEaveOrRake, //Standing Seam
    ExtendedEaveOrRakeForm, //Standing Seam
  },

  data: () => {
    return {
      photoSelected: '',
    };
  },

  created() {
    const self = this;
    const templateNumber = this.$f7route.params.templateNumber;
    const template = this.assemblyTemplateByNumber(templateNumber);

    if (!_.isEmpty(template)) {
      this.initAssemblyTemplate(template);
    } else {
      this.getAssemblyTemplateListBys([
        {
          prop: 'templateNumber',
          val: templateNumber,
          op: '==',
        },
      ]).then(docs => {
        self.initAssemblyTemplate(docs[0]);
      });
    }
  },

  computed: {
    ...mapGetters('common/assembly-template', [
      'assemblyTemplateByNumber',
      'assemblyTemplate',
    ]),
  },

  methods: {
    ...mapActions('common/assembly-template', [
      'getAssemblyTemplateListBys',
      'getAssemblyTemplateById',
    ]),

    initAssemblyTemplate(template) {
      this.$f7.preloader.show();
      this.getAssemblyTemplateById(template.id).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    setAssemblyMeasurement() {},

    handleZoom(type, ref) {
      this.$refs[ref].handleZoom(type);
    },
    setPhotoSelected() {},
    handleBack() {
      this.$f7router.back('/assembly-template', { force: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-content {
  position: relative;
  padding: 0 8px 0 8px;
}
.btn-container {
  display: flex;
  flex-direction: row-reverse;
  // padding: 10px 14px;
  // margin-bottom: 10px;
}
.btn-container .button {
  margin: 0 5px;
  min-width: 120px;
}
.info-label {
  font-weight: 600;
  margin-right: 5px;
}
.btn-zoom {
  position: absolute;
  width: 70px;
  top: 40px;
  right: 20px;

  .button {
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
