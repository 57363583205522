import _ from 'lodash';
import { FirebaseActions } from '../../../../services/firebase.service';
import * as types from './types';

export default tenant => {
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    'project'
  );

  return {
    bindProjectList: projectActions.bindCollection,
    bindProjectListBy: projectActions.bindCollectionBy,
    unbindProjectList: projectActions.unbindCollection,
    bindProject: projectActions.bindDocument,
    unbindProject: projectActions.unbindDocument,
    updateProject: projectActions.updateDocument,

    getProjectListBys: projectActions.getDocumentBys,

    setProjectId({ commit }, id) {
      commit(types.SET_PROJECT_ID, id);
    },

    async getProjectList({ commit, dispatch }, conditions) {
      const data = await dispatch('getProjectListBys', conditions);
      if (_.isEmpty(data)) {
        return;
      }

      commit(types.SET_PROJECT_LIST, data);
    },
  };
};
