<template>
  <div>
    <f7-block-header
      class="display-flex justify-content-space-between align-items-center margin-top margin-bottom"
    >
      <span>Photos</span>
      <f7-icon
        @click.native="handleAddPhoto"
        f7="plus_square_on_square"
        size="20px"
        color="theme-color"
        class="cursor-pointer"
      ></f7-icon>
    </f7-block-header>
    <f7-block
      style="padding: 1px 0"
      strong
      v-if="projectPhotoListGroupLimit.length"
    >
      <photos
        :projectPhotoListGroup="projectPhotoListGroupLimit"
        :loading="loadingPhoto"
        @clickPhoto="clickPhoto"
        @clickEdit="clickEdit"
      ></photos>
      <f7-block>
        <f7-button
          class="display-inline-block margin-top-half margin-bottom"
          outline
          small
          @click.native="seeMore"
        >
          See more
        </f7-button>
      </f7-block>
    </f7-block>
    <f7-block
      v-else
      strong
      style="color: gray"
      >Click icon to add images!</f7-block
    >
    <photo-browser
      :photos="photoList"
      theme="dark"
      ref="pageDark"
      @clickEdit="clickEdit"
      @clickDownload="clickDownload"
    ></photo-browser>
    <edit-photo-popup ref="editPhoto"> </edit-photo-popup>
    <project-photos-popup
      ref="projectPhotosPopup"
      :photos="projectPhotoByAlbum"
      :albumId="albumId"
      :loadingPhoto="loadingPhoto"
    ></project-photos-popup>
    <upload-photo
      :albumId="albumId"
      ref="uploadPhoto"
      :showProgressBar="true"
    ></upload-photo>
  </div>
</template>
<script>
import Photos from './components/Photos.vue';
import EditPhotoPopup from './components/EditPhotoPopup.vue';
import PhotoBrowser from './components/PhotoBrowser.vue';
import UploadPhoto from './components/UploadPhoto.vue';
import ProjectPhotosPopup from '../popup/ProjectPhotosPopup.vue';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { sortLatest } from '../../../../utility/date-time-tool';
import { sleep } from '@/utility/datetime';

export default {
  components: {
    Photos,
    EditPhotoPopup,
    PhotoBrowser,
    ProjectPhotosPopup,
    UploadPhoto,
  },
  props: {
    actionId: String,
  },
  data: () => {
    return {
      loadingPhoto: null,
      albumId: null,
    };
  },
  computed: {
    ...mapGetters('dashboard/photo', [
      'projectPhotoList',
      'projectPhotoSelected',
      'selectedPhotoIds',
    ]),
    ...mapGetters('dashboard/project', ['card']),

    projectPhotoByAlbum() {
      return (this.projectPhotoList || []).filter(
        r => r.albumId === this.albumId
      );
    },
    photosLimit() {
      let list = _.cloneDeep(sortLatest(this.projectPhotoByAlbum));
      let maxPhotoInRow = 3;
      if (this.$device.desktop || this.$device.ipad) {
        // calc photo max show on 1 row from grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        const widthPopup = window.innerWidth / 2;
        const maxContentPhotos = widthPopup - 16 * 2 - 6; // 16px padding and 6px of scroll width
        maxPhotoInRow = (maxContentPhotos - (maxContentPhotos % 100)) / 100;
        let gaps = (maxPhotoInRow - 1) * 8; // space between photos
        let maxWidth = gaps + maxPhotoInRow * 100;
        while (maxWidth > maxContentPhotos) {
          maxPhotoInRow--;
          gaps = (maxPhotoInRow - 1) * 8;
          maxWidth = gaps + maxPhotoInRow * 100;
        }
      }
      list = list.slice(0, maxPhotoInRow);
      return list;
    },
    projectPhotoListGroupLimit() {
      return this.photosLimit.length
        ? [
            {
              key: '',
              data: this.photosLimit,
            },
          ]
        : [];
    },
    photoList() {
      return this.photosLimit.map(i => ({
        id: i.id,
        url: i.photoUrl,
        comments: i.comments,
        photoFullPath: i.photoFullPath,
      }));
    },
  },
  methods: {
    ...mapActions('dashboard/photo', [
      'bindProjectPhotoListBy',
      'downloadPhoto',
      'onSelect',
      'cancelSelectionMode',
    ]),
    ...mapActions('dashboard/album', ['getProjectPhotoAlbumByActionsId']),

    async initData(projectId) {
      if (projectId) {
        this.albumId = null;
        this.loadingPhoto = true;
        let count = 1;
        while (this.albumId === null && count < 10) {
          sleep(1000);
          const album = await this.getProjectPhotoAlbumByActionsId({
            projectId,
            actionId: this.actionId,
          });
          if (!_.isEmpty(album)) {
            this.albumId = album.id;
          }
          count++;
        }

        await this.bindProjectPhotoListBy({
          prop: 'projectId',
          val: projectId,
          op: '==',
        });
        this.loadingPhoto = false;
      }
    },

    clickPhoto(id) {
      if (!this.selectionMode) {
        const index = this.photoList.findIndex(i => i.id === id);
        this.$refs.pageDark.open(index);
      } else {
        this.onSelect(id);
      }
    },
    clickEdit(id) {
      this.$f7.preloader.show();
      const photo = this.projectPhotoList.find(i => i.id === id);
      this.$refs.editPhoto.open(photo);
      this.$f7.preloader.hide();
    },
    seeMore() {
      this.$refs.projectPhotosPopup.open();
    },
    handleAddPhoto() {
      this.$refs.uploadPhoto.openUploadPhoto();
    },
    clickDownload(id) {
      this.onSelect(id);
      this.handleDownload();
    },
    async handleDownload() {
      this.$f7.toast
        .create({
          text: 'Downloading photos...',
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 3000,
        })
        .open();
      const photos = this.projectPhotoSelected(this.selectedPhotoIds);
      photos.forEach(i =>
        this.downloadPhoto({ fullPath: i.photoFullPath, name: i.photoName })
      );
      this.cancelSelectionMode();
    },
  },
};
</script>
