<template>
  <f7-list
    inline-labels
    :inset="$device.desktop"
    :class="{ 'no-margin': $device.desktop }"
    class="margin-bottom"
  >
    <f7-list-input
      label="A"
      type="text"
      placeholder="Enter value"
      :value="data.A"
      :readonly="isDisable"
      @change="setField('A', $event.target.value.trim())"
    ></f7-list-input>
    <f7-list-input
      label="B"
      type="text"
      placeholder="Enter value"
      :value="data.B"
      :readonly="isDisable"
      @change="setField('B', $event.target.value.trim())"
    ></f7-list-input>
    <f7-list-input
      label="C"
      type="text"
      placeholder="Enter value"
      :value="data.C"
      :readonly="isDisable"
      @change="setField('C', $event.target.value.trim())"
    ></f7-list-input>
    <f7-list-input
      label="D"
      type="text"
      placeholder="Enter value"
      :value="data.D"
      :readonly="isDisable"
      @change="setField('D', $event.target.value.trim())"
    ></f7-list-input>
    <f7-list-input
      label="E"
      type="text"
      placeholder="Enter value"
      :value="data.E"
      :readonly="isDisable"
      @change="setField('E', $event.target.value.trim())"
    ></f7-list-input>
    <f7-list-input
      label="F"
      type="text"
      placeholder="Enter value"
      :value="data.F"
      :readonly="isDisable"
      @change="setField('F', $event.target.value.trim())"
    ></f7-list-input>
    <f7-list-input
      label="H"
      type="text"
      placeholder="Enter value"
      :value="data.H"
      :readonly="isDisable"
      @change="setField('H', $event.target.value.trim())"
    ></f7-list-input>
  </f7-list>
</template>

<script>
export default {
  props: {
    data: Object,
    isDisable: { type: Boolean, default: false },
  },

  methods: {
    setField(name, value) {
      this.$emit('setFields', { name, value });
    },
  },
};
</script>
