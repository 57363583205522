<template>
  <div>
    <f7-block-title v-show="$device.desktop">Pitch Pan</f7-block-title>
    <f7-card :class="{ 'margin-top-half': !$device.desktop }">
      <!-- <f7-card-header>Drawing board</f7-card-header> -->
      <f7-card-content>
        <div class="canvas-container">
          <img
            class="display-none"
            :id="
              isDark && dark
                ? `pitch-pan_${entityId}-dark`
                : `pitch-pan_${entityId}`
            "
            :src="`${isDark ? (dark ? pitchPanDark : pitchPan) : pitchPan}`"
          />

          <!-- canvas -->
          <canvas
            v-if="$device.desktop"
            :ref="entityId"
            :id="entityId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @mousedown="handleMouseDown"
            @mousewheel="handleMouseWheel"
          />
          <canvas
            v-else
            :ref="entityId"
            :id="entityId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @click="handleMouseDown"
            @pointerdown="handlePointerDown"
            @pointermove="handlePointerMove"
            @pointerup="handlePointerUp"
            @pointercancel="handlePointerUp"
            @pointerleave="handlePointerUp"
            @pointerout="handlePointerUp"
          />
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>
<script>
import paper from 'paper';
import { evaluate } from 'mathjs';
import canvasMixin from '../../../utility/canvas-mixin';
import _ from 'lodash';
import pitchPanDark from '@/assets/img/metal-diagrams/pitch-pan-dark.jpeg';
import pitchPan from '@/assets/img/metal-diagrams/pitch-pan.png';
const inchToPx = 10;

export default {
  mixins: [canvasMixin],

  props: {
    data: Object,
    entityId: String,
    isDark: { type: Boolean, default: true },
    isMetalDrawing: { type: Boolean, default: false },
  },

  data() {
    return {
      pitchPanDark,
      pitchPan,
      scope: null,
      dataToPx: {},
      draws: {},
      space: 250,
      yBase: 100,
      xBase: 100,
      width: 1200,
      height: 1000,
      dark: localStorage.getItem('themeDark') === 'true',
    };
  },

  watch: {
    data: {
      handler(val) {
        const keys = ['A', 'B', 'C', 'D'];
        for (let i of keys) {
          let parseValue = this.convertStringToExpression(val[i]);
          this.dataToPx[i] = evaluate(parseValue) * inchToPx;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.isMetalDrawing) {
      this.scope = new paper.PaperScope();
      this.scope.setup(this.entityId);
      this.drawAll();
    }
  },

  methods: {
    initCanvas() {
      this.removeProject(this.scope);
      this.scope = new paper.PaperScope();
      this.scope.setup(this.entityId);
      if (!_.isEmpty(this.scope.view)) {
        this.scope.view.viewSize = new paper.Size(
          this.$device.desktop ? this.width : this.widthMobile,
          this.$device.desktop ? this.height : this.heightMobile
        );
      }
    },

    reset() {
      this.scope && this.scope.project.activeLayer.removeChildren();
    },

    setDraws(name, value) {
      this.draws[name] = value;
    },

    handleDraw1() {
      const { A, B, C } = this.dataToPx;
      const pointRectangle1 = new paper.Point(this.xBase, this.yBase + B);
      const sizeRectangle1 = new paper.Size(C + B * 2, A);

      const rectangle1 = new paper.Path.Rectangle({
        point: pointRectangle1,
        size: sizeRectangle1,
        strokeColor: this.isDark ? (this.dark ? 'white' : 'black') : 'black',
        strokeWidth: 2,
      });

      const pointRectangle2 = new paper.Point(
        pointRectangle1.x + B,
        pointRectangle1.y - B
      );
      const sizeRectangle2 = new paper.Size(C, A + B * 2);

      const rectangle2 = new paper.Path.Rectangle({
        point: pointRectangle2,
        size: sizeRectangle2,
        strokeColor: this.isDark ? (this.dark ? 'white' : 'black') : 'black',
        strokeWidth: 2,
      });

      const pointA = rectangle1.segments[0].point;
      const pointB = rectangle2.segments[1].point;
      const pointC = rectangle2.segments[2].point;
      const pointD = rectangle2.segments[0].point;

      this.handleDrawDescriptionLeft(
        this.isDark,
        pointRectangle1,
        pointA,
        `A: ${this.data.A}`,
        50
      );
      this.handleDrawDescriptionTop(
        this.isDark,
        pointRectangle1,
        pointB,
        `B: ${this.data.B}`,
        'topLeft',
        50 + B
      );
      this.handleDrawDescriptionTop(
        this.isDark,
        pointB,
        pointC,
        `C: ${this.data.C}`
      );

      let text = new paper.PointText(pointD.x + C / 3, pointD.y + 50);
      text.content = 'TOP VIEW';
      text.fillColor = this.isDark ? (this.dark ? 'white' : 'black') : 'black';
      text.fontSize = 18;
    },

    handleDraw2() {
      const { B, C, D } = this.dataToPx;
      const x = this.xBase + C + B * 2 + this.space;
      const y = this.yBase;

      const pointRectangle = new paper.Point(x, y);
      const sizeRectangle = new paper.Size(C, D);

      const rectangle = new paper.Path.Rectangle({
        point: pointRectangle,
        size: sizeRectangle,
        strokeColor: this.isDark ? (this.dark ? 'white' : 'black') : 'black',
        strokeWidth: 2,
      });

      const pointM = new paper.Point(x - B, y);
      const pointN = new paper.Point(x + C + B, y);

      new paper.Path({
        segments: [pointM, pointN],
        strokeColor: this.isDark ? (this.dark ? 'white' : 'black') : 'black',
        strokeWidth: 2,
      });

      const pointA = rectangle.segments[0].point;
      const pointD = rectangle.segments[2].point;

      this.handleDrawDescriptionLeft(
        this.isDark,
        pointM,
        pointA,
        `D: ${this.data.D}`,
        50
      );
      this.handleDrawDescriptionTop(
        this.isDark,
        pointM,
        pointN,
        `${this.convertExpesstionToString(`${this.data.C} ${this.data.B}*2`)}`
      );
      this.handleDrawDescriptionBottom(
        this.isDark,
        pointA,
        pointD,
        this.data.C
      );
      this.handleDrawDescriptionBottom(
        this.isDark,
        pointD,
        pointN,
        this.data.B
      );

      let text = new paper.PointText(pointA.x + C / 3, pointA.y + 100);
      text.content = 'SIDE VIEW';
      text.fillColor = this.isDark ? (this.dark ? 'white' : 'black') : 'black';
      text.fontSize = 18;
    },

    handleDraw3() {
      const { A, B } = this.dataToPx;
      let img = new paper.Raster(
        this.isDark && this.dark
          ? `pitch-pan_${this.entityId}-dark`
          : `pitch-pan_${this.entityId}`
      );
      img.size = new paper.Size(441, 308);
      img.position = new paper.Point(
        this.xBase + 420,
        this.yBase + A + B * 2 + 300
      );

      let text = new paper.PointText(img.position.x, img.position.y + 200);
      text.content = 'ISO VIEW';
      text.fillColor = this.isDark ? (this.dark ? 'white' : 'black') : 'black';
      text.fontSize = 18;
    },

    drawAll() {
      this.handleDraw1();
      this.handleDraw2();
      this.handleDraw3();
      this.resizableLayer();
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas-container {
  position: relative;
}
canvas[resize] {
  width: 100%;
  height: 100%;
}
</style>
