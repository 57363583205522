<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Assembly Drawing Detail</f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="save()">Save</f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-block>
      <f7-row>
        <f7-col
          width="100"
          :xsmall="$device.desktop ? '80' : '100'"
          style="position: relative"
        >
          <f7-card v-if="!currentDrawing.assemblyTemplateId">
            <f7-card-content
              style="width: 100%; height: 500px"
            ></f7-card-content>
          </f7-card>
          <component
            v-else
            :is="currentDrawing.canvasComponent"
            :ref="currentDrawing.templateCode"
            :data="currentDrawing.measurement || {}"
            :entityId="currentDrawing.id"
            :isMetalDrawing="true"
            :items="[]"
            @setFields="
              setAssemblyMeasurement($event.name, $event.value, $event.callback)
            "
            :photoSelected="photoSelected"
            @setPhotoSelected="setPhotoSelected"
          ></component>
          <!-- zoom in/out -->
          <f7-segmented
            v-show="currentDrawing.canvasComponent"
            raised
            tag="p"
            class="btn-zoom"
            :style="!$device.desktop && 'top: 0'"
          >
            <f7-button @click="handleZoom('in', currentDrawing.templateCode)"
              >+</f7-button
            >
            <f7-button @click="handleZoom('out', currentDrawing.templateCode)"
              >-</f7-button
            >
          </f7-segmented>
        </f7-col>

        <f7-col
          width="100"
          :xsmall="$device.desktop ? '20' : '100'"
        >
          <!-- common form -->
          <common-measurement-form
            ref="commonMeasurementForm"
            :data="currentDrawing"
            @changeDrawing="changeDrawing"
          ></common-measurement-form>
          <!-- Measurement title -->
          <f7-block-title>Measurement</f7-block-title>

          <component
            :is="currentDrawing.formComponent"
            :data="currentDrawing.measurement || {}"
            :photoSelected="photoSelected"
            @setFields="
              setAssemblyMeasurement($event.name, $event.value, $event.callback)
            "
            @setPhotoSelected="setPhotoSelected"
          ></component>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import CopingWithCleatFastener from '@/components/metal-canvas/canvas/CopingWithCleatFastener.vue';
import CopingWithCleatFastenerForm from '@/components/metal-canvas/forms/CopingWithCleatFastenerForm.vue';
import CopingWithCleat from '@/components/metal-canvas/canvas/CopingWithCleat.vue';
import CopingWithCleatForm from '@/components/metal-canvas/forms/CopingWithCleatForm.vue';

import CopingWithFrontBackCleat from '@/components/metal-canvas/canvas/CopingWithFrontBackCleat.vue';
import CopingWithFrontBackCleatForm from '@/components/metal-canvas/forms/CopingWithFrontBackCleatForm.vue';

import CommonMeasurementForm from '../components/forms/CommonMeasurementForm.vue';
import Embedded from '@/components/metal-canvas/canvas/Embedded.vue';
import EmbeddedForm from '@/components/metal-canvas/forms/EmbeddedForm.vue';
import CollectorHead from '@/components/metal-canvas/canvas/CollectorHead.vue';
import CollectorHeadForm from '@/components/metal-canvas/forms/CollectorHeadForm.vue';
import DownspoutTransition from '@/components/metal-canvas/canvas/DownspoutTransition.vue';
import DownspoutTransitionForm from '@/components/metal-canvas/forms/DownspoutTransitionForm.vue';
import BoxDownspout from '@/components/metal-canvas/canvas/BoxDownspout.vue';
import BoxDownspoutForm from '@/components/metal-canvas/forms/BoxDownspoutForm.vue';
import GutterSystem from '@/components/metal-canvas/canvas/GutterSystem.vue';
import GutterSystemForm from '@/components/metal-canvas/forms/GutterSystemForm.vue';
import HeadwallFlashing from '@/components/metal-canvas/canvas/HeadwallFlashing.vue';
import HeadwallFlashingForm from '@/components/metal-canvas/forms/HeadwallFlashingForm.vue';
import PitchPan from '@/components/metal-canvas/canvas/PitchPan.vue';
import PitchPanForm from '@/components/metal-canvas/forms/PitchPanForm.vue';
import CounterFlashing from '@/components/metal-canvas/canvas/CounterFlashing.vue';
import CounterFlashingForm from '@/components/metal-canvas/forms/CounterFlashingForm.vue';

// Standing Seam
import Valley from '@/components/metal-canvas/canvas/Valley.vue';
import ValleyForm from '@/components/metal-canvas/forms/ValleyForm.vue';
import SidewallOrHeadwall from '@/components/metal-canvas/canvas/SidewallOrHeadwall.vue';
import SidewallOrHeadwallForm from '@/components/metal-canvas/forms/SidewallOrHeadwallForm.vue';
import Ridge from '@/components/metal-canvas/canvas/Ridge.vue';
import RidgeForm from '@/components/metal-canvas/forms/RidgeForm.vue';
import ZeeTrim from '@/components/metal-canvas/canvas/ZeeTrim.vue';
import ZeeTrimForm from '@/components/metal-canvas/forms/ZeeTrimForm.vue';
import ExtendedEaveOrRake from '@/components/metal-canvas/canvas/ExtendedEaveOrRake.vue';
import ExtendedEaveOrRakeForm from '@/components/metal-canvas/forms/ExtendedEaveOrRakeForm.vue';

import _ from 'lodash';
import commonMixins from '../utility/common';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';

import {
  STATUS_ASSEMBLY_DRAWING_ACTIVE,
  STATUS_ASSEMBLY_DRAWING_DRAFT,
} from '../../../utility/const';

export default {
  components: {
    CopingWithCleatFastener,
    CopingWithCleatFastenerForm,
    CopingWithCleat,
    CopingWithCleatForm,

    CopingWithFrontBackCleat,
    CopingWithFrontBackCleatForm,
    Embedded,
    CommonMeasurementForm,
    EmbeddedForm,
    CollectorHead,
    CollectorHeadForm,
    DownspoutTransition,
    DownspoutTransitionForm,
    BoxDownspout,
    BoxDownspoutForm,
    GutterSystem,
    GutterSystemForm,
    HeadwallFlashing,
    HeadwallFlashingForm,
    PitchPan,
    PitchPanForm,
    CounterFlashing,
    CounterFlashingForm,
    Valley, //Standing Seam
    ValleyForm, //Standing Seam
    SidewallOrHeadwall, //Standing Seam
    SidewallOrHeadwallForm, //Standing Seam
    Ridge, //Standing Seam
    RidgeForm, //Standing Seam
    ZeeTrim, //Standing Seam
    ZeeTrimForm, //Standing Seam
    ExtendedEaveOrRake, //Standing Seam
    ExtendedEaveOrRakeForm, //Standing Seam
  },
  mixins: [commonMixins],

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  data: () => {
    return {
      photoSelected: '',
      currentDrawing: {},
    };
  },

  async created() {
    const self = this;
    const drawingNumber = this.$f7route.params.drawingNumber;
    this.isDetail = false;
    this.$f7.preloader.show();
    await this.getAssemblyDrawingListBys([
      {
        prop: 'drawingNumber',
        val: drawingNumber,
        op: '==',
      },
    ]).then(async docs => {
      self.init(docs[0]);
      if (docs[0] && docs[0].priceListId) {
        await this.bindPriceListItem(docs[0].priceListId);
      }
      self.$f7.preloader.hide();
    });
  },

  computed: {
    ...mapGetters({
      assemblyDrawingByNumber:
        'common/assembly-drawing/assemblyDrawingByNumber',
      assemblyDrawing: 'common/assembly-drawing/assemblyDrawing',
      assemblyTemplateList: 'common/assembly-template/assemblyTemplateList',
    }),
    ...mapGetters({
      priceListItem: 'common/price-list/priceListItem',
    }),
  },

  methods: {
    ...mapActions('common/assembly-drawing', [
      'getAssemblyDrawingListBys',
      'setAssemblyDrawing',
      'updateAssemblyDrawingDoc',
    ]),
    ...mapActions('common/price-list', ['bindPriceListItem']),
    init(assemblyDrawing) {
      this.setAssemblyDrawing(assemblyDrawing);
      this.currentDrawing = _.cloneDeep(assemblyDrawing);
    },

    changeDrawing(drawing) {
      let assemblyTemplate =
        _.cloneDeep(
          this.assemblyTemplateList.find(
            r => r.id === drawing.assemblyTemplateId
          )
        ) || {};
      const data = {
        ...this.currentDrawing,
        ...drawing,
        templateCode: assemblyTemplate.templateCode,
        templateName: assemblyTemplate.templateName,
        canvasComponent: assemblyTemplate.canvasComponent,
        formComponent: assemblyTemplate.formComponent,
        cleatNumberOfBend: assemblyTemplate.cleatNumberOfBend,
        numberOfBend: assemblyTemplate.numberOfBend,
        numberOfCleat: assemblyTemplate.numberOfCleat,
        measurement: assemblyTemplate.measurement,
      };
      this.currentDrawing = data;
      setTimeout(() => {
        this.drawAssemply(this.currentDrawing);
      }, 500);
    },

    setAssemblyMeasurement(key, value, callback) {
      this.currentDrawing.measurement[key] = value;
      setTimeout(() => {
        this.drawAssemply(this.currentDrawing);
        if (callback) return callback(key, value);
      }, 500);
    },

    setPhotoSelected(value) {
      this.photoSelected = value;
    },
    saveDrawing() {
      this.$f7.preloader.show();
      this.updateAssemblyDrawingDoc({
        id: this.currentDrawing.id,
        doc: this.currentDrawing,
      }).then(() => this.$f7.preloader.hide());
    },
    save() {
      const self = this;
      this.$refs.commonMeasurementForm.v$.$touch();
      if (this.$refs.commonMeasurementForm.v$.$invalid) {
        return;
      }
      if (
        self.assemblyDrawing.status === STATUS_ASSEMBLY_DRAWING_DRAFT &&
        self.currentDrawing.status === STATUS_ASSEMBLY_DRAWING_DRAFT
      ) {
        this.$ri.dialog.openWarningDialog({
          title: 'Is the drawing complete?',
          content: 'If yes, please update the status to active.',
          textButton: 'Active',
          textCancelButton: 'Just Save',
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
              this.saveDrawing();
            } else if (index === 1) {
              _sefl.app.dialog.close();
              self.currentDrawing.status = STATUS_ASSEMBLY_DRAWING_ACTIVE;
              this.saveDrawing();
            }
          },
        });
      } else {
        this.saveDrawing();
      }
    },

    handleZoom(type, ref) {
      this.$refs[ref].handleZoom(type);
    },
    handleBack() {
      this.$f7router.back('/assembly-drawing', { force: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-content {
  position: relative;
  padding: 0 8px 0 8px;
}

.btn-container {
  display: flex;
  flex-direction: row-reverse;
}

.btn-container .button {
  margin: 0 5px;
  min-width: 120px;
}

.info-label {
  font-weight: 600;
  margin-right: 5px;
}

.btn-zoom {
  position: absolute;
  width: 70px;
  top: 40px;
  right: 20px;

  .button {
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
