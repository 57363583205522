<template>
  <div>
    <f7-block-title v-show="$device.desktop">Headwall Flashing</f7-block-title>
    <f7-card :class="{ 'margin-top-half': !$device.desktop }">
      <!-- <f7-card-header>Drawing board</f7-card-header> -->
      <f7-card-content>
        <div class="canvas-container">
          <canvas
            v-if="$device.desktop"
            :ref="entityId"
            :id="entityId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @mousedown="handleMouseDown"
            @mousewheel="handleMouseWheel"
          />
          <canvas
            v-else
            :ref="entityId"
            :id="entityId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @click="handleMouseDown"
            @pointerdown="handlePointerDown"
            @pointermove="handlePointerMove"
            @pointerup="handlePointerUp"
            @pointercancel="handlePointerUp"
            @pointerleave="handlePointerUp"
            @pointerout="handlePointerUp"
          />
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>

<script>
import paper from 'paper';
import { evaluate } from 'mathjs';
import canvasMixin from '../../../utility/canvas-mixin';
import _ from 'lodash';
const inchToPx = 50;

export default {
  mixins: [canvasMixin],

  props: {
    data: Object,
    entityId: String,
    isDark: { type: Boolean, default: true },
    isMetalDrawing: { type: Boolean, default: false },
  },

  data: () => ({
    scope: null,
    dataToPx: {},
    yBase: 100,
    xBase: 200,
    width: 1000,
    height: 800,
    dark: localStorage.getItem('themeDark') === 'true',
  }),

  watch: {
    data: {
      handler(val) {
        const keys = ['A', 'B', 'C'];
        for (let i of keys) {
          let parseValue = this.convertStringToExpression(val[i]);
          this.dataToPx[i] = evaluate(parseValue) * inchToPx;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.isMetalDrawing) {
      this.scope = new paper.PaperScope();
      this.scope.setup(this.entityId);
      this.drawAll();
    }
  },

  methods: {
    initCanvas() {
      this.removeProject(this.scope);
      this.scope = new paper.PaperScope();
      this.scope.setup(this.entityId);
      if (!_.isEmpty(this.scope.view)) {
        this.scope.view.viewSize = new paper.Size(
          this.$device.desktop ? this.width : this.widthMobile,
          this.$device.desktop ? this.height : this.heightMobile
        );
      }
    },

    reset() {
      this.scope && this.scope.project.activeLayer.removeChildren();
    },

    handleDraw() {
      const { A, B, C } = this.dataToPx;
      const pointA = new paper.Point(this.xBase, this.yBase);
      const pointB = new paper.Point(pointA.x, pointA.y + A);
      let pointC = new paper.Point(pointB.x, pointB.y - B);
      pointC = pointC.rotate(110, pointB);
      let pointD = new paper.Point(pointC.x, pointC.y + C);
      pointD = pointD.rotate(-30, pointC);

      let path = new paper.Path({
        strokeColor: this.isDark ? (this.dark ? 'white' : 'black') : 'black',
        strokeWidth: 3,
      });

      path.add(pointA, pointB, pointC, pointD);

      this.handleDrawDescriptionLeft(
        this.isDark,
        pointA,
        pointB,
        `A: ${this.data.A}`
      );
      this.handleDrawDescriptionDiagonal(
        this.isDark,
        pointC,
        pointB,
        110,
        0,
        `B: ${this.data.B}`,
        'center'
      );

      this.handleDrawDescriptionDiagonal(
        this.isDark,
        pointD,
        pointC,
        -30,
        0,
        `C: ${this.data.C}`,
        'center',
        50,
        'positive'
      );
    },

    drawAll() {
      this.handleDraw();
      this.resizableLayer(this.xBase, this.yBase * 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas-container {
  position: relative;
}
canvas[resize] {
  width: 100%;
  height: 100%;
}
</style>
