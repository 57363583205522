<template>
  <div>
    <f7-block-title v-show="$device.desktop">Box Downspout</f7-block-title>
    <f7-card :class="{ 'margin-top-half': !$device.desktop }">
      <!-- <f7-card-header>Drawing board</f7-card-header> -->
      <f7-card-content>
        <div class="canvas-container">
          <img
            class="display-none"
            :id="isDark && dark ? 'box-downspout-dark' : 'box-downspout'"
            :src="`${
              isDark ? (dark ? boxDownspoutDark : boxDownspout) : boxDownspout
            }`"
          />
          <canvas
            v-if="$device.desktop"
            :ref="entityId"
            :id="entityId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @mousedown="handleMouseDown"
            @mousewheel="handleMouseWheel"
          />
          <canvas
            v-else
            :ref="entityId"
            :id="entityId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @click="handleMouseDown"
            @pointerdown="handlePointerDown"
            @pointermove="handlePointerMove"
            @pointerup="handlePointerUp"
            @pointercancel="handlePointerUp"
            @pointerleave="handlePointerUp"
            @pointerout="handlePointerUp"
          />
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>

<script>
import paper from 'paper';
import { evaluate } from 'mathjs';
import canvasMixin from '../../../utility/canvas-mixin';
import _ from 'lodash';
import boxDownspoutDark from '@/assets/img/metal-diagrams/box-downspout-dark.jpg';
import boxDownspout from '@/assets/img/metal-diagrams/box-downspout.png';

const inchToPx = 50;

export default {
  mixins: [canvasMixin],

  props: {
    data: Object,
    entityId: String,
    isDark: { type: Boolean, default: true },
    isMetalDrawing: { type: Boolean, default: false },
  },

  data: () => ({
    boxDownspoutDark,
    boxDownspout,
    scope: null,
    dataToPx: {},
    yBase: 300,
    xBase: 300,
    width: 1000,
    height: 1000,
    dark: localStorage.getItem('themeDark') === 'true',
  }),
  watch: {
    data: {
      handler(val) {
        const keys = ['A', 'B', 'length'];
        for (let i of keys) {
          let parseValue = this.convertStringToExpression(val[i]);
          this.dataToPx[i] = evaluate(parseValue) * inchToPx;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.isMetalDrawing) {
      this.scope = new paper.PaperScope();
      this.scope.setup(this.entityId);
      this.drawAll();
    }
  },
  methods: {
    initCanvas() {
      this.removeProject(this.scope);
      this.scope = new paper.PaperScope();
      this.scope.setup(this.entityId);

      if (!_.isEmpty(this.scope.view)) {
        this.scope.view.viewSize = new paper.Size(
          this.$device.desktop ? this.width : this.widthMobile,
          this.$device.desktop ? this.height : this.heightMobile
        );
      }
    },

    reset() {
      this.scope && this.scope.project.activeLayer.removeChildren();
    },

    handleDraw1() {
      let img = new paper.Raster(
        this.isDark && this.dark ? 'box-downspout-dark' : 'box-downspout'
      );
      img.size = new paper.Size(567, 422);
      img.position = new paper.Point(this.xBase, this.yBase);
    },

    handleDraw2() {
      const { A, B } = this.dataToPx;
      const x = this.xBase + 150;
      const y = this.yBase + 250;

      const pointA = new paper.Point(x, y);
      const pointB = new paper.Point(pointA.x, pointA.y + B);
      let pointC = pointB.clone();
      pointC.x += A;
      let pointD = pointA.clone();
      pointD.x += A;

      let path1 = new paper.Path({
        strokeColor: this.isDark ? (this.dark ? 'white' : 'black') : 'black',
        strokeWidth: 3,
      });
      path1.add(pointA, pointB, pointC, pointD);

      let pointE = pointD.clone();
      pointE.x -= A / 2 - 20;
      let pointE1 = new paper.Point(pointE.x, pointE.y + 8);
      pointE1 = pointE1.rotate(60, pointE);
      let pointE2 = new paper.Point(pointE1.x - 25, pointE1.y);
      let pointE3 = new paper.Point(pointE2.x, pointE2.y - 5);
      const centerE2E3 = this.pointOnPathByStart(pointE2, pointE3, 2);
      const throughE2E3 = pointE2.rotate(90, centerE2E3);
      let pointE4 = new paper.Point(pointE3.x + 25, pointE3.y);

      let path2 = new paper.Path({
        strokeColor: this.isDark ? (this.dark ? 'white' : 'black') : 'black',
        strokeWidth: 3,
      });
      path2.add(pointD, pointE, pointE1, pointE2);
      path2.arcTo(throughE2E3, pointE3);
      path2.add(pointE4);

      let pointF = pointE.clone(); // EF = 20
      pointF.x -= 40;
      let pointF1 = new paper.Point(pointF.x, pointF.y - 8);
      pointF1 = pointF1.rotate(60, pointF);
      let pointF2 = new paper.Point(pointF1.x + 25, pointF1.y);
      let pointF3 = new paper.Point(pointF2.x, pointF2.y + 5);
      const centerF2F3 = this.pointOnPathByStart(pointF2, pointF3, 2);
      const throughF2F3 = pointF2.rotate(90, centerF2F3);
      let pointF4 = new paper.Point(pointF3.x - 25, pointF3.y);

      let path3 = new paper.Path({
        strokeColor: this.isDark ? (this.dark ? 'white' : 'black') : 'black',
        strokeWidth: 3,
      });
      path3.add(pointA, pointF, pointF1, pointF2);
      path3.arcTo(throughF2F3, pointF3);
      path3.add(pointF4);

      path1.join(path2);
      path1.join(path3);

      this.handleDrawDescriptionTop(
        this.isDark,
        pointA,
        pointD,
        `A: ${this.data.A}`,
        'topCenter',
        70
      );
      this.handleDrawDescriptionLeft(
        this.isDark,
        pointA,
        pointB,
        `B: ${this.data.B}`
      );
    },

    handleDrawValue() {
      // draw value
      let showValue = new paper.PointText(350, 50);
      showValue.content = `A = ${this.data.A}; B = ${this.data.B}; LENGTH = ${this.data.length}`;
      showValue.fillColor = this.isDark
        ? this.dark
          ? 'white'
          : 'black'
        : 'black';
      showValue.fontSize = 18;
    },

    drawAll() {
      this.handleDraw1();
      this.handleDraw2();
      this.handleDrawValue();
      this.resizableLayer(this.xBase / 2, this.yBase / 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas-container {
  position: relative;
}
canvas[resize] {
  width: 100%;
  height: 100%;
}
</style>
