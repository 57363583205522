<template>
  <div>
    <f7-block-title v-show="$device.desktop">Valley</f7-block-title>
    <f7-card :class="{ 'margin-top-half': !$device.desktop }">
      <f7-card-content>
        <div class="canvas-container">
          <!-- canvas -->
          <canvas
            v-if="$device.desktop"
            :ref="entityId"
            :id="entityId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @mousedown="handleMouseDown"
            @mousewheel="handleMouseWheel"
          />
          <canvas
            v-else
            :ref="entityId"
            :id="entityId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @click="handleMouseDown"
            @pointerdown="handlePointerDown"
            @pointermove="handlePointerMove"
            @pointerup="handlePointerUp"
            @pointercancel="handlePointerUp"
            @pointerleave="handlePointerUp"
            @pointerout="handlePointerUp"
          />
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>
<script>
import paper from 'paper';
import { evaluate } from 'mathjs';
import canvasMixin from '../../../utility/canvas-mixin';
import _ from 'lodash';
const inchToPx = 40;
const radius = 5;

export default {
  mixins: [canvasMixin],

  props: {
    data: Object,
    entityId: String,
    isDark: { type: Boolean, default: true },
    isMetalDrawing: { type: Boolean, default: false },
  },

  data() {
    return {
      scope: null,
      dataToPx: {},
      draws: {},
      yBase: 0,
      xBase: 100,
      width: 1000,
      height: 800,
      dark: localStorage.getItem('themeDark') === 'true',
    };
  },

  watch: {
    data: {
      handler(val) {
        const keys = ['A', 'B', 'C', 'D'];
        for (let i of keys) {
          let parseValue = this.convertStringToExpression(val[i]);
          this.dataToPx[i] = evaluate(parseValue) * inchToPx;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.isMetalDrawing) {
      this.scope = new paper.PaperScope();
      this.scope.setup(this.entityId);
      this.drawAll();
    }
  },

  methods: {
    initCanvas() {
      this.removeProject(this.scope);
      this.scope = new paper.PaperScope();
      this.scope.setup(this.entityId);
      if (!_.isEmpty(this.scope.view)) {
        this.scope.view.viewSize = new paper.Size(
          this.$device.desktop ? this.width : this.widthMobile,
          this.$device.desktop ? this.height : this.heightMobile
        );
      }
    },

    reset() {
      this.scope && this.scope.project.activeLayer.removeChildren();
    },

    setDraws(name, value) {
      this.draws[name] = value;
    },

    handleDraw() {
      let path1 = new paper.Path({
        strokeColor: this.isDark ? (this.dark ? 'white' : 'black') : 'black',
        strokeWidth: '3',
      });
      let path2 = new paper.Path({
        strokeColor: this.isDark ? (this.dark ? 'white' : 'black') : 'black',
        strokeWidth: '3',
      });

      const { A, B, C, D } = this.dataToPx;
      const pathHeight = A + C + 4 * inchToPx;
      const space = pathHeight / Math.sqrt(2);
      const pointA = new paper.Point(this.xBase + space, this.yBase + A);
      const pointA1 = new paper.Point(pointA.x, pointA.y - A + radius);
      const pointA2 = new paper.Point(pointA1.x - radius * 2, pointA1.y);
      const centerA1A2 = this.pointOnPathByStart(pointA1, pointA2, 2);
      const throughA1A2 = pointA1.rotate(-90, centerA1A2);

      const pointB = new paper.Point(pointA2.x, pointA2.y + 4 * inchToPx);
      const pointB1 = new paper.Point(pointB.x, pointB.y + B - radius);
      const pointB2 = new paper.Point(pointB1.x - radius * 2, pointB1.y);
      const centerB1B2 = this.pointOnPathByStart(pointB1, pointB2, 2);
      const throughB1B2 = pointB1.rotate(90, centerB1B2);

      const pointB3 = new paper.Point(pointB2.x, pointB2.y - B + radius);
      const pointB4 = new paper.Point(pointB3.x - radius * 2, pointB3.y);
      const centerB3B4 = this.pointOnPathByStart(pointB3, pointB4, 2);
      const throughB3B4 = pointB3.rotate(-90, centerB3B4);

      const pointC = new paper.Point(pointB4.x, pointB4.y + C - radius);
      const pointD = new paper.Point(pointC.x + D, pointC.y);

      const pointE = new paper.Point(pointD.x + D, pointD.y);
      const pointF = new paper.Point(pointE.x, pointE.y - C + radius);
      const pointF1 = new paper.Point(pointF.x - radius * 2, pointF.y);
      const centerFF1 = this.pointOnPathByStart(pointF, pointF1, 2);
      const throughFF1 = pointF.rotate(-90, centerFF1);

      const pointF2 = new paper.Point(pointF1.x, pointF1.y + B - radius);
      const pointF3 = new paper.Point(pointF2.x - radius * 2, pointF2.y);
      const centerF2F3 = this.pointOnPathByStart(pointF2, pointF3, 2);
      const throughF2F3 = pointF2.rotate(90, centerF2F3);

      const pointG = new paper.Point(
        pointF3.x,
        pointF3.y - 4 * inchToPx - B + radius
      );
      const pointG1 = new paper.Point(pointG.x - radius * 2, pointG.y);
      const centerGG1 = this.pointOnPathByStart(pointG, pointG1, 2);
      const throughGG1 = pointG.rotate(-90, centerGG1);
      const pointG2 = new paper.Point(pointG1.x, pointG1.y + A - radius);

      path1.add(pointA, pointA1);
      path1.arcTo(throughA1A2, pointA2);
      path1.add(pointB, pointB1);
      path1.arcTo(throughB1B2, pointB2);
      path1.add(pointB3);
      path1.arcTo(throughB3B4, pointB4);
      path1.add(pointC, pointD);

      path2.add(pointD, pointE, pointF);
      path2.arcTo(throughFF1, pointF1);
      path2.add(pointF2);
      path2.arcTo(throughF2F3, pointF3);
      path2.add(pointG);
      path2.arcTo(throughGG1, pointG1);
      path2.add(pointG2);

      path1.rotate(-45, pointD);
      path2.rotate(45, pointD);

      path1.join(path2);

      this.handleDrawDescriptionDiagonal(
        this.isDark,
        pointA.rotate(-45, pointD),
        throughA1A2.rotate(-45, pointD),
        315,
        0,
        `A: ${this.data.A}`,
        'center'
      );

      this.handleDrawDescriptionDiagonal(
        this.isDark,
        throughB1B2.rotate(-45, pointD),
        pointB.rotate(-45, pointD),
        315,
        0,
        `B: ${this.data.B}`,
        'center'
      );

      this.handleDrawDescriptionDiagonal(
        this.isDark,
        throughB3B4.rotate(-45, pointD),
        pointC.rotate(-45, pointD),
        -45,
        0,
        `C: ${this.data.C}`,
        'center'
      );

      this.handleDrawDescriptionDiagonal(
        this.isDark,
        pointC.rotate(-45, pointD),
        pointD,
        45,
        0,
        `D: ${this.data.D}`,
        'center'
      );
    },

    drawAll() {
      this.handleDraw();
      const { A, C } = this.dataToPx;
      const pathHeight = A + C + 4 * inchToPx;
      const space = pathHeight / Math.sqrt(2);
      this.resizableLayer(this.xBase + space, this.yBase + space);
    },
  },
};
</script>
<style lang="scss" scoped>
.canvas-container {
  position: relative;
}
canvas[resize] {
  width: 100%;
  height: 100%;
}
</style>
