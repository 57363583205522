<template>
  <div>
    <f7-row class="row-action">
      <f7-col
        ><f7-row class="justify-content-flex-start align-items-center"
          ><f7-block-title
            >Assembly {{ `#${index + 1}` }}
          </f7-block-title></f7-row
        ></f7-col
      >
      <f7-col
        ><f7-row class="justify-content-flex-end"
          ><f7-button
            v-if="!$device.desktop"
            outline
            style="margin-top: 6px; margin-right: 10px"
            @click="$emit('removeAssembly', index)"
            >Remove</f7-button
          >
          <f7-button
            v-if="$device.desktop && assembly.assemblyItem"
            outline
            style="margin-top: 6px; margin-right: 10px"
            @click="openPreviewPopup"
            >Preview</f7-button
          >
        </f7-row></f7-col
      >
    </f7-row>
    <!-- <f7-block-title></f7-block-title> -->
    <f7-list
      :inset="$device.desktop"
      :class="{ 'no-margin': $device.desktop }"
    >
      <!-- <f7-list-input
        placeholder="Enter Assembly Name"
        label="Assembly Name"
        :value="assembly.assemblyName"
        @change="
          changeAssemblyProp(index, 'assemblyName', $event.target.value.trim())
        "
      >
      </f7-list-input> -->

      <f7-list-input
        v-if="showDrawing"
        :disabled="disableDrawing"
        placeholder="Select Assembly Drawing"
        label="Assembly Drawing"
        type="select"
        :value="assembly.id"
        @change="onChangeAssemblyDrawing(index, $event.target.value)"
      >
        <option
          value=""
          hidden
        >
          Select Assembly Drawing
        </option>
        <option
          v-for="(item, index) in assemblyList"
          :key="index"
          :value="item.id"
        >
          {{ `${item.drawingNumber} - ${item.drawingName}` }}
        </option>
      </f7-list-input>

      <f7-list-input
        readonly
        placeholder="Enter Assembly Template"
        label="Assembly Template"
        :value="assembly.templateName"
      >
      </f7-list-input>

      <!-- <f7-list-input
        placeholder="Select Assembly Template"
        label="Assembly Template"
        type="select"
        :value="assembly.id"
        @change="
          $emit('changeTemplate', {
            target: { index, value: assemblyDrawingById($event.target.value) }
          })
        "
      >
        <option value="" hidden>Select Assembly Item</option>
        <option
          v-for="(item, index) in assemblyList"
          :key="index"
          :value="item.id"
          >{{ item.templateName }}</option
        >
      </f7-list-input> -->

      <f7-list-item
        header="GA"
        class="custom-input"
      >
        <f7-input
          slot="title"
          placeholder="Select GA"
          type="select"
          :value="assembly.assemblyGA"
          @change="
            changeAssemblyProp(
              index,
              'assemblyGA',
              $event.target.value.trim(),
              () => {
                changeAssemblyProp(index, 'colorAndManufacturer', '', () => {
                  checkMetalStretchOut(assembly);
                });
              }
            )
          "
        >
          <option
            value=""
            hidden
          >
            Select GA
          </option>
          <option
            v-for="(item, index) in [
              { id: 'ga-22', displayName: '22' },
              { id: 'ga-24', displayName: '24' },
              { id: 'ga-26', displayName: '26' },
            ]"
            :key="index"
            :value="item.id"
          >
            {{ item.displayName }}
          </option>
        </f7-input>
      </f7-list-item>

      <!-- Color and Manufacturer-->
      <f7-list-input
        v-show="!!assembly.assemblyGA"
        placeholder="Select Color"
        label="Color"
        type="select"
        :value="assembly.colorAndManufacturer"
        @change="
          changeAssemblyProp(
            index,
            'colorAndManufacturer',
            $event.target.value.trim()
          )
        "
      >
        <option value="">Select color</option>
        <option
          v-for="(item, index) in productColorAndManufacturers(
            subCategoryByName('Assembly Metal').id,
            assembly.assemblyGA
          )"
          :key="index"
          :value="item.value"
        >
          {{ item.displayName }}
        </option>
      </f7-list-input>
      <!--  Cleat GA -->
      <f7-list-input
        v-if="assembly.numberOfCleat > 0"
        placeholder="Select Cleat GA"
        label="Cleat GA"
        type="select"
        :value="assembly.assemblyCleatGA"
        @change="
          changeAssemblyProp(
            index,
            'assemblyCleatGA',
            $event.target.value.trim(),
            () => {
              checkMetalStretchOut(assembly);
            }
          )
        "
      >
        <option
          value=""
          hidden
        >
          Select Cleat GA
        </option>
        <option
          v-for="(item, index) in [
            { id: 'ga-22-cleat', displayName: '22' },
            { id: 'ga-24-cleat', displayName: '24' },
            { id: 'ga-26-cleat', displayName: '26' },
          ]"
          :key="index"
          :value="item.id"
        >
          {{ item.displayName }}
        </option>
      </f7-list-input>
      <!-- 
      <f7-list-item
        :ref="`smartSelectColor`"
        title="Color"
        smart-select
        :smart-select-params="{
          index: index,
          openIn: 'popover',
          on: {
            closed: saveSelectedColor
          }
        }"
      >
        <select name="color" multiple v-model="selectedColors">
          <option v-for="(color, i) in productColors" :key="i" :value="color">{{
            color
          }}</option>
        </select>
      </f7-list-item>

      <f7-list-item
        :ref="`smartSelectManufacturer`"
        title="Manufacturer"
        smart-select
        :smart-select-params="{
          index: index,
          openIn: 'popover',
          on: {
            closed: saveSelectedManufacturer
          }
        }"
      >
        <select name="manufacturer" multiple v-model="selectedManufacturers">
          <option
            v-for="(manufacturer, i) in productManufacturers"
            :key="i"
            :value="manufacturer"
            >{{ manufacturer }}</option
          >
        </select>
      </f7-list-item> -->

      <f7-list-item
        :ref="`smartSelectFasteningSubstrate_${index}`"
        title="Fastening Substrate"
        smart-select
        :smart-select-params="{
          index: index,
          openIn: 'popover',
          on: {
            closed: saveSelectedFasteningSubstrate,
          },
        }"
      >
        <select
          name="fasteningSubstrate"
          multiple
          v-model="selectedFasteningSubstrates"
        >
          <option
            v-for="(fasteningSubstrate, i) in [
              { displayName: 'Wood', value: 'wood' },
              { displayName: 'Metal', value: 'metal' },
              { displayName: 'Plastic', value: 'plastic' },
            ]"
            :key="i"
            :value="fasteningSubstrate.value"
          >
            {{ fasteningSubstrate.displayName }}
          </option>
        </select>
      </f7-list-item>

      <f7-list-input
        :placeholder="`Enter ${isQuantity ? 'Qty (EA)' : 'Length (LF)'} of the metal work`"
        :label="isQuantity ? 'Qty (EA)' : 'Length (LF)'"
        :value="assembly.qty"
        @change="
          onChangeAssemblyQty(
            index,
            'qty',
            parseFloat($event.target.value.trim()),
            assembly,
            () => {
              checkMetalStretchOut(assembly);
            }
          )
        "
      >
      </f7-list-input>

      <f7-list-input
        readonly
        label="Stretch Out calc (in)"
        :value="stretchOut"
      >
      </f7-list-input>

      <f7-list-input
        readonly
        label="# of Bends per 10 foot item"
        :value="assembly.numberOfBend"
      >
      </f7-list-input>

      <f7-list-input
        v-if="assembly.numberOfCleat === 1"
        readonly
        label="Cleat Stretch out (in)"
        :value="cleatStretchOut"
      >
      </f7-list-input>

      <f7-list-input
        v-if="assembly.numberOfCleat === 1"
        readonly
        label="# of Bends for Cleat"
        :value="assembly.cleatNumberOfBend"
      >
      </f7-list-input>

      <f7-list-input
        v-if="assembly.numberOfCleat === 2"
        readonly
        label="Front Face Cleat Stretch out (in)"
        :value="frontCleatStretchOut"
      >
      </f7-list-input>

      <f7-list-input
        v-if="assembly.numberOfCleat === 2"
        readonly
        label="# of Bends for Front Face Cleat"
        :value="assembly.cleatNumberOfBend[0]"
      >
      </f7-list-input>

      <f7-list-input
        v-if="assembly.numberOfCleat === 2"
        readonly
        label="Back Face Cleat Stretch out (in)"
        :value="backCleatStretchOut"
      >
      </f7-list-input>

      <f7-list-input
        v-if="assembly.numberOfCleat === 2"
        readonly
        label="# of Bends for Back Face Cleat"
        :value="assembly.cleatNumberOfBend[1]"
      >
      </f7-list-input>
    </f7-list>

    <metal-preview-popup
      ref="previewPopup"
      :item="assembly"
      :index="index"
      :assemblyList="assemblyList"
    >
    </metal-preview-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  getCleatStretchOut,
  getBackCleatStretchOut,
  getStretchOut,
} from '../../utility/stretch-out-helper';
import buildingMixins from '../../utility/building-mixins';
import MetalPreviewPopup from '../../components/popup/MetalPreviewPopup.vue';
import {
  assemblyTemplateCodeIsQuantityType,
  calculateAssemblyLength,
} from '../../utility/assembly-data';

export default {
  props: {
    assembly: Object,
    index: [Number, String],
    roofType: String,
    canvasId: String,
  },

  mixins: [buildingMixins],

  components: {
    MetalPreviewPopup,
  },

  data() {
    return {
      selectedColors: null,
      selectedManufacturers: null,
      selectedFasteningSubstrates: null,

      disableDrawing: false,
      showDrawing: true,
    };
  },

  mounted() {
    if (
      this.$refs[`smartSelectColor`] &&
      this.$refs[`smartSelectManufacturer`]
    ) {
      this.$refs[`smartSelectColor`].f7SmartSelect.setValueText(
        (this.assembly.colors || []).join(', ')
      );
      this.$refs[`smartSelectManufacturer`].f7SmartSelect.setValueText(
        (this.assembly.manufacturers || []).join(', ')
      );
    }
  },

  methods: {
    reDrawSelectAssembly() {
      this.showDrawing = false;
      this.$nextTick(() => {
        this.showDrawing = true;
      });
    },

    saveSelectedColor(el) {
      const colors = el.getValue() || [];
      this.changeAssemblyProp(this.index, 'colors', colors);
    },

    saveSelectedManufacturer(el) {
      const manufacturers = el.getValue() || [];
      this.changeAssemblyProp(this.index, 'manufacturers', manufacturers);
    },

    saveSelectedFasteningSubstrate(el) {
      const fasteningSubstrates = el.getValue() || [];
      this.changeAssemblyProp(
        this.index,
        'fasteningSubstrates',
        fasteningSubstrates
      );
    },
    changeAssemblyProp(index, prop, value, cb) {
      this.$emit('changeAssemblyProp', index, prop, value, cb);
    },

    openPreviewPopup() {
      const canvas = this.$parent.$parent.$el.querySelector(
        `[id='${this.canvasId}']`
      );

      const ctx = canvas.getContext("2d");

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      const threshold = 210;

      for (let i = 0; i < data.length; i += 4) {
        if (
          data[i] > threshold &&
          data[i + 1] > threshold &&
          data[i + 2] > threshold
        ) {
          data[i] = 0;
          data[i + 1] = 0;
          data[i + 2] = 0;
        }
      }

      const blurredImageData = this.applyGaussianBlur(imageData);

      ctx.putImageData(blurredImageData, 0, 0);

      const urlBase64 = canvas.toDataURL("image/png");

      this.$refs.previewPopup.open(urlBase64);
    },

    applyGaussianBlur(imageData) {
      const pixels = imageData.data;
      const width = imageData.width;
      const height = imageData.height;
      const kernel = [1, 2, 1, 2, 4, 2, 1, 2, 1];
      const divisor = 16;
      const halfSide = Math.floor(Math.sqrt(kernel.length));
      const outputData = new Uint8ClampedArray(width * height * 4);

      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          const idx = (y * width + x) * 4;
          let r = 0,
            g = 0,
            b = 0;
          for (let cy = 0; cy < halfSide * 2 + 1; cy++) {
            for (let cx = 0; cx < halfSide * 2 + 1; cx++) {
              const scy = Math.min(height - 1, Math.max(0, y + cy - halfSide));
              const scx = Math.min(width - 1, Math.max(0, x + cx - halfSide));
              const srcOff = (scy * width + scx) * 4;
              const wt = kernel[cy * (halfSide * 2 + 1) + cx];
              r += pixels[srcOff] * wt;
              g += pixels[srcOff + 1] * wt;
              b += pixels[srcOff + 2] * wt;
            }
          }
          outputData[idx] = r / divisor;
          outputData[idx + 1] = g / divisor;
          outputData[idx + 2] = b / divisor;
          outputData[idx + 3] = pixels[idx + 3];
        }
      }
      return new ImageData(outputData, width, height);
    },

    onChangeAssemblyDrawing(index, drawingId) {
      const sefl = this;
      this.$f7.preloader.show();
      this.disableDrawing = true;
      this.$emit('changeTemplate', {
        target: { index, value: this.assemblyDrawingById(drawingId) },
        callback: () => {
          sefl.disableDrawing = false;
          sefl.$f7.preloader.hide();
        },
      });
    },

    onChangeAssemblyQty(index, prop, value, item, cb) {
      const callback = cb;
      this.changeAssemblyProp(index, prop, value, () => {
        const length = calculateAssemblyLength({ assembly: item, qty: value });
        this.changeAssemblyProp(index, 'length', length, callback);
      });
    },
  },

  computed: {
    ...mapGetters('estimate/estimate-page/product-item', [
      'productColors',
      'productManufacturers',
      'productColorAndManufacturers',
    ]),

    ...mapGetters('estimate/estimate-page/estimate/sub-category', [
      'subCategoryByName',
    ]),

    ...mapGetters('estimate/estimate-page/estimate', ['currentBuilding']),
    ...mapGetters('estimate/estimate-page/assembly-drawing', {
      assemblyList: 'assemblyDrawingList',
      assemblyDrawingById: 'assemblyDrawingById',
    }),

    isQuantity() {
      return assemblyTemplateCodeIsQuantityType.includes(
        this.assembly.assemblyItem
      );
    },

    stretchOut() {
      let value = getStretchOut.call(
        this.assembly.measurement || {},
        this.assembly.assemblyItem
      );
      return value;
    },

    cleatStretchOut() {
      let value = getCleatStretchOut.call(
        this.assembly.measurement || {},
        this.assembly.assemblyItem
      );
      return value;
    },

    frontCleatStretchOut() {
      return this.cleatStretchOut;
    },

    backCleatStretchOut() {
      let value = getBackCleatStretchOut.call(
        this.assembly.measurement || {},
        this.assembly.assemblyItem
      );
      return value;
    },
  },

  watch: {
    assembly: {
      deep: true,
      immediate: true,
      handler(value) {
        this.selectedFasteningSubstrates = [];
        this.selectedFasteningSubstrates = JSON.parse(
          JSON.stringify(value.fasteningSubstrates || [])
        );
      },
    },

    assemblyList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          this.reDrawSelectAssembly();
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-input ::v-deep .item-title {
  width: 100%;
}
</style>
